import { asyncSliceCreator } from '@/utils';
import * as storeAPI from '@/api/store';

const [tagsSlice, { name: tagsSliceName }] = asyncSliceCreator('tags', storeAPI.getStoreTags);

export default {
  state: () => ({
    ...tagsSlice.state(),
  }),
  mutations: {
    ...tagsSlice.mutations,
  },
  actions: {
    ...tagsSlice.actions,
  },
  getters: {
    ...tagsSlice.getters,
    tags: state =>
      state[tagsSliceName].data
        ?.filter(tag => tag.enable && tag.screen?.includes('tags'))
        .sort((a, b) => a.order - b.order) ?? [],
    homePageTags: state =>
      state[tagsSliceName].data
        ?.filter(tag => tag.enable && tag.screen?.includes('home'))
        .sort((a, b) => a.order - b.order) ?? [],
  },
};
