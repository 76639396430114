
import { wrapperDecorations } from '@/utils';

const TYPES = ['secondary', 'accent', 'success', 'warning', 'danger'];

export default {
  functional: true,
  props: {
    strong: Boolean,
    underline: Boolean,
    del: Boolean,
    code: Boolean,
    italic: Boolean,
    keyboard: Boolean,
    mark: Boolean,
    type: {
      type: String,
    },
    className: String,
  },
  render(createElement, ctx) {
    const { type, className } = ctx.props;

    const currentContent = wrapperDecorations(createElement, ctx.scopedSlots.default(), ctx.props);

    return createElement(
      'span',
      {
        class: {
          'aha-typography-text': true,
          [`aha-typography-text-${type}`]: TYPES.includes(type),
          [className]: !!className,
        },
      },
      [currentContent],
    );
  },
};
