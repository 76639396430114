import axios from 'axios';
import { installAxiosInterceptors } from '@/api/JWTHandler';
import queryString from 'qs';

const axiosInstance = axios.create({
  baseURL: process.env.NUXT_ENV_API_URL,
  timeout: 7000,
  paramsSerializer: function (params) {
    return queryString.stringify(params, { arrayFormat: 'comma' });
  },
});

axiosInstance.CancelToken = axios.CancelToken;
axiosInstance.isCancel = axios.isCancel;

export { axiosInstance };

export default async context => {
  const { app, store } = context;

  installAxiosInterceptors(axiosInstance, store);
};
