import { render, staticRenderFns } from "./error.vue?vue&type=template&id=3b451777&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseTypographyTitle: require('/usr/src/app/components/base/Typography/Title.vue').default,BaseTypographyText: require('/usr/src/app/components/base/Typography/Text.vue').default,BaseLink: require('/usr/src/app/components/base/Link/Link.vue').default})
