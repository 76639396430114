// load sdk
var Amplify, Analytics, Auth;
const loadAmplifyPromise = new Promise(resolve => {
  import(
    /* webpackMode: "lazy" */
    '@aws-amplify/core'
  ).then(loaded_module => {
    Amplify = loaded_module.default;
    resolve();
  });
});

const loadAnalyticsPromise = new Promise(resolve => {
  import(
    /* webpackMode: "lazy" */
    '@aws-amplify/analytics'
  ).then(loaded_module => {
    Analytics = loaded_module.default;
    resolve();
  });
});

const loadAuthPromise = new Promise(resolve => {
  import(
    /* webpackMode: "lazy" */
    '@aws-amplify/auth'
  ).then(dt => {
    Auth = dt;
    resolve();
  });
});

export default function (app) {
  const { store } = app;
  // prepare default attributes
  const globalPinPointAttrs = {
    platform: 'web',
  };

  const getGlobalPinPointAttributes = () => {
    if (globalPinPointAttrs.user_id) return globalPinPointAttrs;

    const userProfile = (store && store.state && store.state.userProfile) || {};

    if (userProfile) {
      const { mobile, name, city } = userProfile;

      Object.assign(globalPinPointAttrs, {
        user_id: mobile,
        city_id: city || '',
        device_id: 'web',
      });
    }

    return globalPinPointAttrs;
  };

  const awsPinpoint_SesstionStart = () => {
    const globalAttrs = getGlobalPinPointAttributes();
    try {
      Analytics &&
        Analytics.record({
          name: '_session_start',
          attributes: {
            ...globalAttrs,
          },
        });
    } catch (e) {
      console.log(e);
    }
  };

  const awsPinpoint_SesstionStop = () => {
    const globalAttrs = getGlobalPinPointAttributes();
    try {
      Analytics &&
        Analytics.record({
          name: '_session_stop',
          attributes: {
            ...globalAttrs,
          },
        });
    } catch (e) {
      console.log(e);
    }
  };

  const initAWSPinpoint = () => {
    Promise.all([loadAmplifyPromise, loadAnalyticsPromise, loadAuthPromise]).then(() => {
      const amplifyConfig = {
        Auth: {
          // REQUIRED - Amazon Cognito Identity Pool ID
          identityPoolId: 'us-east-1:a8259204-b6df-4abd-bf13-dccc836a671c',

          // REQUIRED - Amazon Cognito Region
          region: 'us-east-1',
        },
      };

      Auth.default.configure(amplifyConfig);

      const analyticsConfig = {
        AWSPinpoint: {
          appId: 'c8bc94fe23a84a63bb59083f81a739b3',
          region: 'us-east-1',
          mandatorySignIn: false,
        },
      };
      Analytics.configure(analyticsConfig);

      // set global
      window.AWSAnalytics = Analytics;

      document.addEventListener(
        'visibilitychange',
        () => {
          if (document.hidden) {
            awsPinpoint_SesstionStop();
          } else {
            awsPinpoint_SesstionStart();
          }
        },
        { passive: true },
      );
      window.addEventListener(
        'beforeunload',
        () => {
          awsPinpoint_SesstionStop();
        },
        { passive: true },
      );
    });
  };

  initAWSPinpoint();
}
