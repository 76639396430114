//
//
//
//
//
//

import { Layout as AntLayout } from 'ant-design-vue';

export default {
  components: {
    AntLayoutFooter: AntLayout.Footer,
  },
};
