//
//
//
//
//
//

import { Layout as AntLayout } from 'ant-design-vue';

export default {
  components: {
    AntLayoutContent: AntLayout.Content,
  },
};
