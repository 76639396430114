import Vue from 'vue';
import {
  WEB_IN_APP_CONFIG,
  ORDER_STATUS,
  WEB_IN_APP_AHAMOVE_WEB_ID,
  WEB_IN_APP_AHAMOVE_MOBILE_ID,
  WEB_IN_APP_MOMO_ID,
  WEB_IN_APP_VIETTELPAY_ID,
  COOKIE_EXPIRES_MAX_AGE__3M,
  PAYMENT_METHODS,
} from '@/mixins/constances';
import { getCookie, getToken, getRecordUrl, setCookie, storeToken } from '@/plugins/utils';
import { viettelPayActivate, momoActivate } from '@/api/auth';
import { getLatestOrderByToken } from '@/api/order';
import { postMessage, EVENT_HIDE_TOOLBAR } from '@/plugins/postMessage';

// Make sure to pick a unique name for the flag
// so it won't conflict with any other mixin.
if (!Vue.__webInApp_mixin__) {
  Vue.__webInApp_mixin__ = true;
  Vue.mixin({
    methods: {
      withPartnerPath(to) {
        let path = to;
        const webInAppConfig = this.$store.state.webInAppConfig;
        if (webInAppConfig) {
          path = `/${webInAppConfig.id}${to}`;
        }
        return path;
      },
    },
  });
}

// Get config from route path
const getWebInAppConfig = route => {
  const { path, query } = route;

  const webInAppId = path.split('/')[1];

  let config = WEB_IN_APP_CONFIG.find(({ id }) => id === webInAppId);

  if (webInAppId === WEB_IN_APP_VIETTELPAY_ID) {
    // Store dynamic params receive from query params when ViettelPay embed webinapp URL
    config = {
      ...config,
      msisdn: query.msisdn,
      time: parseInt(query.time),
      check_sum: query.check_sum,
    };
  } else if (webInAppId === WEB_IN_APP_MOMO_ID) {
    // Store dynamic params receive from query params when ViettelPay embed webinapp URL
    config = {
      ...config,
      name: query.name,
      email: query.email,
      mobile: query.mobile,
      time: parseInt(query.time),
      checksum: query.checksum,
    };
  }

  return config;
};

const isAhaMoveWebInApp = config =>
  [WEB_IN_APP_AHAMOVE_MOBILE_ID, WEB_IN_APP_AHAMOVE_WEB_ID].includes(config.id);

const isShoppingService = serviceId => serviceId?.includes('GOSHOP');

const isOrderInProcess = status => ![ORDER_STATUS.CANCELLED, ORDER_STATUS.COMPLETED].includes(status);

export default async function ({ route, store, app, $sentry }) {
  try {
    const config = getWebInAppConfig(route);

    if (!config) return;

    const defaultPaymentMethod = config.paymentMethods?.[0] ?? PAYMENT_METHODS.CASH_BY_RECIPIENT;

    store.dispatch('setWebInAppConfig', config);
    store.dispatch('v2/updatePaymentMethod', defaultPaymentMethod);

    // Handler for specific partner
    if (config.id === WEB_IN_APP_MOMO_ID) {
      // MomoMiniAPI.init({ appId: config.appId });
      const response = await momoActivate({
        type: config.activate_type,
        webinapp: config.id,
        name: config.name,
        email: config.email,
        mobile: config.mobile,
        time: config.time,
        checksum: config.checksum,
      });
      console.log(response);

      const { token, user } = response.data;

      store.dispatch('setToken', token);

      storeToken(token);
      setCookie('token', token, {
        exdays: COOKIE_EXPIRES_MAX_AGE__3M,
        domain: process.env.NUXT_ENV_APP_DOMAIN,
      });

      store.dispatch('setUserProfile', user);
    } else if (config.id === WEB_IN_APP_VIETTELPAY_ID) {
      const response = await viettelPayActivate({
        type: config.activate_type,
        webinapp: config.id,
        msisdn: config.msisdn,
        time: config.time,
        merchant_code: config.merchant_code,
        check_sum: config.check_sum,
      });

      const { token, user } = response.data;

      store.dispatch('setToken', token);

      storeToken(token);
      setCookie('token', token, {
        exdays: COOKIE_EXPIRES_MAX_AGE__3M,
        domain: process.env.NUXT_ENV_APP_DOMAIN,
      });

      store.dispatch('setUserProfile', user);
    } else if (config.id === WEB_IN_APP_AHAMOVE_MOBILE_ID) {
      postMessage(EVENT_HIDE_TOOLBAR).catch(err => {
        $sentry.captureException(err);
      });
    }

    const isAuthenticated = !!getCookie('token') || !!getToken();

    if (!isAuthenticated) {
      store.dispatch('setRequiredAuthModal', true);
    } else if (!isAhaMoveWebInApp(config)) {
      const latestOrderResponse = await getLatestOrderByToken();
      const { service_id, status, _id } = latestOrderResponse.data;
      if (isShoppingService(service_id) && isOrderInProcess(status)) {
        // Redirect to record detail
        const recordUrl = getRecordUrl(_id, config);
        window.open(recordUrl, '_self');
      }
    }
  } catch (err) {
    app?.$sentry.captureException(err);
  }
}
