export const types = {
  INIT: 'deliveryInfo/init',
  UPDATE_ADDRESS: 'deliveryInfo/updateAddress',
  UPDATE_NAME: 'deliveryInfo/updateName',
  UPDATE_MOBILE: 'deliveryInfo/updateMobile',
  UPDATE_REMARKS: 'deliveryInfo/updateRemarks',
};

const DELIVERY_INFO_STORAGE_KEY = 'deliveryInfo';

export default {
  state: () => ({
    coordinate: undefined,
    address: undefined,
    name: null,
    mobile: null,
    remarks: null,
  }),
  mutations: {
    [types.INIT](state, info) {
      state.coordinate = info.coordinate;
      state.address = info.address;
      state.name = info.name ?? null;
      state.mobile = info.mobile ?? null;
      state.remarks = info.remarks ?? null;
    },
    [types.UPDATE_ADDRESS](state, { address, coordinate }) {
      state.address = address;
      state.coordinate = coordinate;

      localStorage.setItem(DELIVERY_INFO_STORAGE_KEY, JSON.stringify(state));
    },
    [types.UPDATE_NAME](state, name) {
      state.name = name;
      localStorage.setItem(DELIVERY_INFO_STORAGE_KEY, JSON.stringify(state));
    },
    [types.UPDATE_MOBILE](state, mobile) {
      state.mobile = mobile;
      localStorage.setItem(DELIVERY_INFO_STORAGE_KEY, JSON.stringify(state));
    },
    [types.UPDATE_REMARKS](state, remarks) {
      state.remarks = remarks;

      localStorage.setItem(DELIVERY_INFO_STORAGE_KEY, JSON.stringify(state));
    },
  },
  actions: {
    intitDeliveryInfo({ commit }) {
      try {
        const info = JSON.parse(localStorage.getItem(DELIVERY_INFO_STORAGE_KEY) ?? '{}');

        commit(types.INIT, info);
      } catch (error) {
        console.log(error);
      }
    },
    updateDeliveryInfo({ commit }, payload) {
      if (payload.coordinate && payload.address) {
        commit(types.UPDATE_ADDRESS, {
          coordinate: payload.coordinate,
          address: payload.address,
        });
      }
      if (payload.name) {
        commit(types.UPDATE_NAME, payload.name);
      }
      if (payload.mobile) {
        commit(types.UPDATE_MOBILE, payload.mobile);
      }
      if (payload.remarks) {
        commit(types.UPDATE_REMARKS, payload.remarks);
      }
    },
    updateAddress({ commit }, payload) {
      commit(types.UPDATE_ADDRESS, {
        coordinate: payload.coordinate,
        address: payload.address,
      });
    },
  },
  getters: {
    deliveryInfo: state => state,
    deliveryInfoCoordicate: state => state.coordinate,
    deliveryAddress: state => state.address,
  },
};
