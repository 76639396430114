//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Portal } from 'portal-vue';

export default {
  components: {
    Portal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    destroyOnClose: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rendered() {
      return this.visible || !this.destroyOnClose;
    },
  },
};
