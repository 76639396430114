//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      requiredAuthModal: state => state.requiredAuthModal,
    }),
  },
  methods: {
    redirectToLoginPage() {
      const { origin, pathname, search } = window.location;
      const searchParams = new URLSearchParams(search);

      let redirectUrl = `${origin}${pathname}?${searchParams.toString()}`;
      if (window.encodeURIComponent) {
        redirectUrl = window.encodeURIComponent(redirectUrl);
      }

      window.open(
        `${process.env.NUXT_ENV_APP_AUTHENTICATE}?source=shopping&redirect_url=${redirectUrl}`,
        '_self',
      );
    },
  },
};
