import { asyncSliceCreator } from '@/utils';
import * as groupAPI from '@/api/group';

// const [userOrder] = asyncSliceCreator('userOrder', groupAPI.getListGroups);

export default {
  state: () => ({
    // ...userOrder.state(),
    userOrder: {
      _id: '227WFJYV',
      currency: 'VND',
      user_id: '84999900022',
      group_deal_id: '61efa61b7e49dc43ff26bb59',
    },
  }),
  mutations: {
    // ...userOrder.mutations,
  },
  actions: {
    // ...userOrder.actions,
  },
  getters: {
    // ...userOrder.getters,
    userOrder: state => state.userOrder,
  },
};
