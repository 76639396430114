import smoothscroll from 'smoothscroll-polyfill';
import * as constances from '@/mixins/constances';
import { trackingEvent, EVENT } from '@/plugins/gtm';
const httpStatus = require('http-status-codes');
import Vue from 'vue';

// Support smooth scrolling on IOS
smoothscroll.polyfill();

Vue.prototype.$constances = constances;
Vue.prototype.$trackingEvent = trackingEvent;
Vue.prototype.$EVENT = EVENT;
Vue.prototype.$httpStatus = httpStatus;
