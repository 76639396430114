
import { wrapperDecorations } from '@/utils';

const TYPES = ['secondary', 'accent', 'success', 'warning', 'danger'];

export default {
  functional: true,
  props: {
    level: {
      type: [Number, String],
      default: 1,
    },
    underline: Boolean,
    del: Boolean,
    italic: Boolean,
    mark: Boolean,
    type: {
      type: String,
    },
    className: String,
  },
  render(createElement, ctx) {
    const { type, level, className } = ctx.props;

    const currentContent = wrapperDecorations(createElement, ctx.scopedSlots.default(), ctx.props);

    return createElement(
      `h${level}`,
      {
        class: {
          'aha-typography-title': true,
          [`aha-typography-title-${type}`]: TYPES.includes(type),
          [className]: !!className,
        },
      },
      [currentContent],
    );
  },
};
