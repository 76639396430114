import jwtDecode from 'jwt-decode';
import memoize from 'memoizee';
import {
  COOKIE_EXPIRES_MAX_AGE__3M,
  STORE_INPUT_DEFAULT_ID,
  SHOPPING_ADMIN_NOTE,
  OPEN_HOURS_MAP,
  WEB_IN_APP_MOMO_ID,
  WEB_IN_APP_VIETTELPAY_ID,
} from '@/mixins/constances';
import dayjs from 'dayjs';

import { orderBy, isNumber } from '@/plugins/lodash';
import { friendlyUrl } from '@/store/utils';

export const storeToken = token => {
  localStorage.setItem('token', token);
};

export const getToken = () => {
  return localStorage.getItem('token');
};

export const getCookie = name => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
};

export const setCookie = (cname, cvalue, options = {}) => {
  const { exdays, domain } = options;

  const d = new Date();
  d.setTime(d.getTime() + (exdays || COOKIE_EXPIRES_MAX_AGE__3M) * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();

  let cookie = `${cname}=${cvalue};${expires};path=/`;

  if (domain) {
    cookie += `;Domain=${domain}`;
  }

  document.cookie = cookie;
};

export const parseHTTPStatusCode = str => {
  let status = 400; // set default status code 400
  let code = '';

  if (str) {
    const regex = /^\d{3}/gim;
    const matches = str.match(regex) || [];

    if (matches[0] && !isNaN(matches[0])) status = parseInt(matches[0]);

    code = str.substr(status.toString().length, str.length).trim();
  }

  return [status, code];
};
export const transformEstimateFeeV2Error = (raw = {}) => {
  const [status, code] = parseHTTPStatusCode(raw.http_code);
  return {
    _id: raw._id,
    isError: true,
    data: { ...raw },
    status,
    code,
  };
};

// Middleware to handle data from estimateFeeV2 api
export const estimateFeeV2Middleware =
  (config = {}) =>
  func => {
    const { targetDataId = null, targetDataIndex = null } = config;

    return new Promise((resolve, reject) => {
      func
        .then(response => {
          const data = response.data;

          if (data && Array.isArray(data) && data.length > 0) {
            if (targetDataId || targetDataIndex || targetDataIndex === 0) {
              // Check target data to resolve/reject
              let item;
              if (!item && targetDataId) {
                item = data.find(i => i._id === targetDataId);
              }

              if (!item && (targetDataIndex || targetDataIndex === 0)) {
                item = data[targetDataIndex];
              }

              if (item && item.title) return reject(transformEstimateFeeV2Error(item));
              else return resolve(item);
            } else {
              // Transform all existed error
              // then resolve data as same as normal flow
              const _data = data.map(item => {
                if (item.title) {
                  return transformEstimateFeeV2Error(item);
                } else return item;
              });

              return resolve(_data);
            }
          } else {
            return resolve(response);
          }
        })
        .catch(e => {
          return reject(e);
        });
    });
  };

const PointFields = [
  'lat',
  'lng',
  'address',
  'short_address',
  'formatted_address',
  'types',
  'name',
  'mobile',
  'cod',
  'tracking_number',
  'remarks',
  'apt_number',
  'building',
  'require_pod',
  'pod_type',
  'types',
  'adr_source',
  'fb_conversation_id',
  'address_code',
  'admin_note',
];

export const pickObjectData = (obj, fields) => {
  if (!fields || !fields[0]) return { ...obj };

  return Object.fromEntries(Object.entries(obj).filter(([key]) => fields.includes(key)));
};

export const cleanPathsData = (paths, pickCustomFields) => {
  let cleanedPaths = [];

  if (paths && paths.length > 0) {
    const fields = pickCustomFields || PointFields;

    paths.forEach((point, index) => {
      const pickObject = pickObjectData(point, fields);
      // pick(point, fields);

      for (var key in pickObject) {
        // remove empty fields
        // remove cod field in pickup point
        if (pickObject[key] === '' || (index === 0 && key === 'cod')) {
          delete pickObject[key];
        }
      }
      cleanedPaths.push(pickObject);
    });
  } else {
    console.error('Cannot clean empty paths');
  }

  return cleanedPaths;
};

export const getDeliveryOptionRequestObjectFromService = service => {
  const requests =
    service &&
    service.requests &&
    service.requests.filter(r => {
      return r.delivery_option === true;
    });
  return requests && requests[0];
};

export const getOrderRequestParams = (state, options = {}) => {
  const {
    shouldStringifyObject, // stringify data for /v1/order/create
    forEstimateFee, // for estimate fee only
    items, // items on cart
    store, // info of store
    cod, // cart price same as cod
    user = {}, // user profile
  } = options;
  const params = {};

  const userInfo = {
    ...state.path[0],
  };

  if (!userInfo.name) {
    userInfo.name = user.name || '';
  }

  if (!userInfo.mobile) {
    userInfo.mobile = user.mobile || '';
  }

  // Get pickup point info
  let storeInfo = {};

  if (items && items.length > 0 && items[0].store_id === STORE_INPUT_DEFAULT_ID) {
    storeInfo = {
      lat: state.pickupPoint.lat,
      lng: state.pickupPoint.lng,
      address: state.pickupPoint.address || state.pickupPoint.name || '',
      mobile: state.pickupPoint.mobile || '',
      name: state.pickupPoint.name || '',
      auto_confirm: true,
      admin_note: SHOPPING_ADMIN_NOTE,
    };
  } else if (store && store._id) {
    storeInfo = {
      lat: store.location.coordinates[1] || store.lat,
      lng: store.location.coordinates[0] || store.lng,
      address: store.address || store.name || '',
      mobile: store.mobile || '',
      name: store.name || '',
      remarks: state.store_note || '',
      auto_confirm: store.auto_confirm,
      _id: store._id,
      admin_note: store.admin_note || '',
      city_id: store.city_id,
    };
  }

  // combine as order's path
  const originPath = [storeInfo, userInfo];
  const path = cleanPathsData(originPath);
  const requests = [];

  params.remarks = state.remarks;

  params.service_id = state.service._id || `${storeInfo.city_id}-${state.service_id}`;

  params.payment_method = state.payment_method;

  // validate promo code for create order
  if (
    state.promo_code &&
    (forEstimateFee || (state.estimatedFee && !state.estimatedFee.promo_error_message))
  ) {
    params.promo_code = state.promo_code;
  }

  // Update valid orderTime/Idle time
  params.order_time = 0;

  // check delivery option request
  const deliveryOptionRequest = getDeliveryOptionRequestObjectFromService(state.service);

  if (deliveryOptionRequest) {
    originPath.forEach((point, index) => {
      if (point.delivery_option) {
        // enable delivery option
        requests.push({
          _id: deliveryOptionRequest._id,
          price: deliveryOptionRequest.price,
          path_index: index,
        });
      } else {
        // disable delivery option
        delete path[index].apt_number;
        delete path[index].building;
      }
    });
  } else {
    // disable delivery option
    path.forEach(p => {
      delete p.apt_number;
      delete p.building;
    });
  }

  params.requests = shouldStringifyObject ? JSON.stringify(requests) : requests;
  params.path = shouldStringifyObject ? JSON.stringify(path) : path;

  if (storeInfo._id) {
    params.store_id = storeInfo._id;
  }

  // Add params for create order/shopping services
  if (!forEstimateFee) {
    if (!storeInfo.auto_confirm) {
      params.need_confirm = true;
    }

    if (state.estimatedFee) {
      params.total_pay = state.estimatedFee.total_pay;
    }
  }

  // shopping items
  if (items) {
    let _items = [];

    items.forEach((i, index) => {
      let item = { ...i };

      if (item.store_id === STORE_INPUT_DEFAULT_ID) {
        item.store_id = 99;
        item._id = parseInt(`9${index}`);
      }

      _items.push(item);
    });

    params.items = shouldStringifyObject ? JSON.stringify(_items) : _items;
  }

  // Schedule order
  if (state.scheduleOrder) {
    params.order_time = state.scheduleOrder.startDateTime.unix();
    params.idle_until = state.scheduleOrder.startDateTime.unix();
  }

  return params;
};

export const cleanCartItems = (items = []) => {
  let result = [];
  let price = 0;

  items.forEach(item => {
    if (item && item._id) {
      const itemFee = (!isNaN(item.price) && parseFloat(item.price)) || 0;
      const quantity = (!isNaN(item.quantity) && parseInt(item.quantity)) || 0;
      let itemCustomizationsFee = 0;

      const payload = {
        _id: item._id,
        store_id: item.store_id,
        name: item.name,
        note: item.note,
        price: itemFee,
        num: quantity,
      };

      if (item.customizations && item.customizations.length > 0) {
        item.customizations.forEach(c => {
          const options = [];

          if (c && c.options) {
            c.options.forEach(o => {
              if (o && o._id && o.selected) {
                const c_price = (!isNaN(o.price) && parseFloat(o.price)) || 0;

                itemCustomizationsFee += c_price;
                options.push({
                  _id: o._id,
                  name: o.name,
                  price: c_price,
                });
              }
            });
          }

          if (options.length > 0) {
            const customizations = payload.customizations || [];

            customizations.push({
              options,
              name: c.name || '',
            });

            payload.customizations = customizations;
          }
        });
      }

      const totalPrice = itemFee + itemCustomizationsFee;

      price += totalPrice * quantity;
      payload.price = totalPrice * quantity;

      result.push(payload);
    }
  });

  return [result, price];
};

export const validatePhoneNumber = value => {
  if (value) {
    var cleanedValue = typeof value === 'string' ? value.trim() : value.toString();
    const pattern = /^\+\d+$|^\d+$/;
    const isNumber = pattern.test(cleanedValue);
    if (
      isNumber &&
      ((cleanedValue.charAt(0) === '+' && cleanedValue.length > 5) ||
        (cleanedValue.charAt(0) !== '+' && cleanedValue.length > 4)) &&
      cleanedValue.length < 16
    ) {
      return true;
    }
  } else return true;

  return 'invalid_mobile';
};

export const getUserProfileFromToken = token => {
  token = token || getCookie('token') || getToken() || '';

  if (token) {
    try {
      const decodedToken = jwtDecode(token);

      if (decodedToken && decodedToken.cid) {
        return {
          mobile: decodedToken.cid,
          name: decodedToken.noc || '',
          city: decodedToken.cty || '',
        };
      }
    } catch (e) {
      console.error(e);
    }
  }

  return null;
};

export const getOpenDates = memoize(
  openDates => {
    try {
      openDates = openDates && JSON.parse(openDates);

      if (openDates && Array.isArray(openDates) && openDates.length > 0) {
        openDates = openDates.map(i => i.trim());

        const dates = openDates[0];

        const excludeWeekday = openDates
          .slice(1)
          .filter(i => isNaN(i) && i[0] === '-')
          .map(i => i.substr(1));

        const onlyWeekday = openDates.slice(1).filter(i => isNaN(i) && i[0] !== '-');

        const excludeDates = openDates
          .slice(1)
          .filter(i => !isNaN(i) && i < 0)
          .map(i => i.substr(1));

        const includeDates = openDates.slice(1).filter(i => !isNaN(i) && i > 0);

        if (dates) {
          const dateRange = dates.split('-');

          if (dateRange.length === 2) {
            const list = [];
            let [startDate, endDate] = dateRange;

            if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
              startDate = dayjs()
                .month(startDate.substr(2, 4) - 1)
                .date(startDate.substr(0, 2));
              endDate = dayjs()
                .month(endDate.substr(2, 4) - 1)
                .date(endDate.substr(0, 2))
                .format('YYYY-MM-DD');

              let date = startDate.format('YYYY-MM-DD');

              while (date <= endDate) {
                const dateObj = dayjs(date);
                const dayOfWeek = dateObj.format('ddd').toLowerCase();

                if (onlyWeekday.length > 0) {
                  if (onlyWeekday.includes(dayOfWeek)) {
                    list.push(dateObj.format('MMDD'));
                  }
                } else if (!excludeWeekday.includes(dayOfWeek)) {
                  list.push(dateObj.format('MMDD'));
                }

                date = dateObj.add(1, 'day').format('YYYY-MM-DD');
              }

              includeDates.forEach(i => !list.includes(i) && list.push(i));

              return list.filter(i => !excludeDates.includes(i));
            }

            return list;
          }
        }
      }
    } catch (e) {
      console.error(e);
    }

    return [];
  },
  { maxAge: 60000 },
);

export const getNextOpenTime = memoize(
  (openDates, openHours) => {
    openDates = getOpenDates(openDates);
    openHours = (openHours && JSON.parse(openHours)) || {};

    let nextOpenTime;
    let isNowOpen = false;

    const now = dayjs();

    const day = now.format('DD');
    const month = now.format('MM');
    const minute = now.minute() + now.hour() * 60;

    // get next open dates from now
    const nextOpenDates = openDates.filter(date => {
      return date && parseInt(date) >= parseInt(`${month}${day}`);
    });

    try {
      if (nextOpenDates.length > 0) {
        nextOpenDates.some((i, index) => {
          if (index < 7) {
            if (i) {
              // parse raw date & set in dayjs
              const nextMonth = i.substr(0, 2);
              const nextDate = i.substr(2, 4);

              if (!isNaN(nextDate) && !isNaN(nextMonth)) {
                const next = dayjs()
                  .month(nextMonth - 1)
                  .date(nextDate)
                  .startOf('d');

                const ddd = OPEN_HOURS_MAP.get(next.format('ddd').toLowerCase());

                if (openHours[ddd] && openHours[ddd].length > 0) {
                  const dayOpenHours = [...openHours[ddd]]
                    .filter(h => h.start_time <= h.end_time)
                    .sort((a, b) => a.start_time - b.start_time);

                  // check if store is open now
                  if (index === 0 && next.isSame(now, 'd')) {
                    dayOpenHours.some(hours => {
                      if (minute >= hours.start_time && minute <= hours.end_time) {
                        isNowOpen = true;
                        return true;
                      }

                      return false;
                    });

                    dayOpenHours.some((hours, index) => {
                      if (minute < hours.start_time && index === 0) {
                        nextOpenTime = dayjs(next).add(hours.start_time, 'm');
                        return true;
                      }

                      if (
                        index < dayOpenHours.length - 1 &&
                        minute > hours.end_time &&
                        minute < dayOpenHours[index + 1].start_time
                      ) {
                        nextOpenTime = dayjs(next).add(dayOpenHours[index + 1].start_time, 'm');
                        return true;
                      }

                      return false;
                    });

                    if (nextOpenTime) return true;
                  }

                  if (next.isAfter(now, 'd')) {
                    nextOpenTime = dayjs(next).add(dayOpenHours[0].start_time, 'm');
                    return true;
                  }
                }
              }
            }

            return false;
          } else {
            return true;
          }
        });

        return [nextOpenTime, isNowOpen];
      }
    } catch (e) {
      console.error(e);
    }

    return [nextOpenTime, isNowOpen];
  },
  { maxAge: 60000 },
);

/**
 * [formatNextOpenTime description]
 * @param  {[day.js object]} day []
 * @return {[string]}     []
 */
export const formatNextOpenTime = (day, locale = 'vi') => {
  const formatTemplate = {
    vi: {
      sameDay: '[Mở cửa vào] hh:mm A',
      nextDay: '[Mở cửa vào] hh:mm A, [ngày mai]',
      nextWeek: '[Mở cửa vào] hh:mm A, dddd',
      sameElse: '[Mở cửa vào] hh:mm A, [ngày] D [thg] M',
    },
    en: {
      sameDay: '[Opens at] hh:mm A',
      nextDay: '[Opens Tomorrow at] hh:mm A',
      nextWeek: '[Opens on] ddd [at] hh:mm: A',
      sameElse: '[Opens on] MMM D',
    },
  };

  return day.locale(locale).calendar(null, formatTemplate[locale]);
};

export const convertStore = (store = {}, locale = 'vi') => {
  const convertedStore = { ...store };

  if (convertedStore.promo1) {
    convertedStore.promo_code = convertedStore.promo1.promo_code;
    convertedStore.promo_code_desc = convertedStore.promo1.description;
  }

  if (isNumber(convertedStore.distance)) {
    convertedStore.distance = `${(convertedStore.distance / 1000).toFixed(2)} km`;
  }

  if (convertedStore.open_dates && convertedStore.open_hours) {
    const [nextOpenTime, isNowOpen] = getNextOpenTime(
      JSON.stringify(convertedStore.open_dates),
      JSON.stringify(convertedStore.open_hours),
    );

    convertedStore.is_open = isNowOpen;

    if (!isNowOpen && nextOpenTime) {
      convertedStore.nextOpenTime = formatNextOpenTime(nextOpenTime, locale);
    }
  }

  convertedStore.friendlyUrl = `/stores/${friendlyUrl(convertedStore.name)}-${convertedStore._id}`;

  return convertedStore;

  // return {
  //   ...convertedStore,
  //   is_open: true,
  //   is_closed: false,
  //   nextOpenTime: undefined,
  //   open_hours: {
  //     6: [{ end_time: 1415, start_time: 420, },],
  //   },
  // };
};

export const convertStoreList = (data = [], locale = 'vi') => {
  const payload = [];

  data.forEach(item => {
    let store = {};

    // item as group of stores
    if (item.stores && item.stores.length > 0) {
      let nearbyStores = [];
      let avaiableStore;

      item.stores.forEach(s => {
        nearbyStores.push(convertStore({ ...s }));
      });

      if (nearbyStores.length > 1) {
        nearbyStores = orderBy(nearbyStores, ['is_open'], ['desc']);

        store.nearbyStores = {
          ...item,
          stores: nearbyStores,
        };

        nearbyStores.some(s => {
          if (s.is_open) {
            avaiableStore = s;
            return true;
          }

          return false;
        });
      }

      if (avaiableStore) {
        store = { ...avaiableStore, ...store };
      } else {
        store = { ...nearbyStores[0], ...store };
      }
    } else {
      store = convertStore({ ...item });
    }

    if (store._id) {
      payload.push(store);
    }
  });

  return payload;
};

export function removeUnicode(str = '') {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');

  return str;
}

export const mapCartItems = (items, storeCategories) => {
  let newItems = [];

  if (storeCategories && storeCategories.length > 0) {
    items.forEach(item => {
      const storeCategory = storeCategories.find(c => c._id === item.item_type_id);

      if (storeCategory && storeCategory.items && storeCategory.items.length > 0) {
        const newItem = storeCategory.items.find(i => i._id === item._id);

        if (newItem) {
          const _newItem = JSON.parse(JSON.stringify(newItem));
          const selectedCustomizations = [];

          if (item.customizations && item.customizations.length > 0) {
            item.customizations.forEach(c => {
              if (c && c.options) {
                c.options.forEach(o => {
                  if (o && o.selected) {
                    selectedCustomizations.push(o._id);
                  }
                });
              }
            });
          }

          if (_newItem.customizations && _newItem.customizations.length > 0) {
            _newItem.customizations.forEach(c => {
              if (c && c.options) {
                c.options.forEach(o => {
                  if (selectedCustomizations.includes(o._id)) {
                    o.selected = true;
                  }
                });
              }
            });
          }

          newItems.push({
            ..._newItem,
            unique_id_in_cart: item.unique_id_in_cart,
            note: item.note,
            quantity: item.quantity,
          });
        }
      }
    });
  }

  return newItems;
};

export const copyToClipboard = value => {
  if (value) {
    const textarea = document.createElement('textarea');
    textarea.setAttribute('readonly', true);
    textarea.textContent = value;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand('copy');

    document.body.removeChild(textarea);
  }
};

// https://stackoverflow.com/questions/217578/how-can-i-determine-whether-a-2d-point-is-within-a-polygon
const cities = {
  HAN: [
    [105.527381, 21.313548],
    [106.187445, 21.307618],
    [106.233813, 20.800651],
    [105.419077, 20.793215],
  ],
  SGN: [
    [106.487164, 11.049175],
    [106.953148, 11.112442],
    [107.372207, 10.331055],
    [106.178869, 10.437468],
  ],
};

/*
 * [detectCityFromLocation description]
 * @param  {[lng, lat]} location [description]
 * @return {[type]}          [description]
 */
export const detectCityFromLocation = (location = []) => {
  let city = 'SGN';

  if (Array.isArray(location) && location.length === 2 && !isNaN(location[0]) && !isNaN(location[1])) {
    Object.keys(cities).some(key => {
      if (isPointInsidePolygon(location, cities[key])) {
        city = key;
        return true;
      }

      return false;
    });
  }

  return city;
};

export const isPointInsidePolygon = (point, polyPoints) => {
  const x = point[0],
    y = point[1];

  let inside = false;
  for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
    let xi = polyPoints[i][0],
      yi = polyPoints[i][1];
    let xj = polyPoints[j][0],
      yj = polyPoints[j][1];

    let intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

export const getRedirectUrl = webInAppConfig => {
  const { origin, pathname, search } = window.location;

  const searchParams = new URLSearchParams(search);

  if (webInAppConfig && webInAppConfig.id) {
    searchParams.set('webinapp', webInAppConfig.id);
  }

  let redirectUrl = `${origin}${pathname}?${searchParams.toString()}`;

  if (window.encodeURIComponent) {
    redirectUrl = window.encodeURIComponent(redirectUrl);
  }

  return redirectUrl;
};

export const getRecordUrl = (orderId, webInAppConfig) => {
  const searchParams = new URLSearchParams();

  const { origin } = window.location;

  if (webInAppConfig && webInAppConfig.id) {
    searchParams.set('webinapp', webInAppConfig.id);

    if (webInAppConfig.id === WEB_IN_APP_MOMO_ID) {
      searchParams.set('refId', webInAppConfig.refId);
      //* Only for dev tool (Testing environment)
      // searchParams.set('appId', webInAppConfig.appId);
    }

    if (webInAppConfig.id === WEB_IN_APP_VIETTELPAY_ID) {
      searchParams.set('activate_type', webInAppConfig.activate_type);
      searchParams.set('msisdn', webInAppConfig.msisdn);
      searchParams.set('time', webInAppConfig.time);
      searchParams.set('check_sum', webInAppConfig.check_sum);
      searchParams.set('merchant_code', webInAppConfig.merchant_code);
    }
  }

  // Redirect URL: GoShop homepage with webinapp configs
  let redirectUrl = `${origin}?${searchParams.toString()}`;

  // Encode redirectUrl
  if (window.encodeURIComponent) {
    redirectUrl = window.encodeURIComponent(redirectUrl);
  }

  searchParams.set('redirectUrl', redirectUrl);

  let recordUrl = `${process.env.NUXT_ENV_APP_WEB}/record/${orderId}?${searchParams.toString()}`;

  return recordUrl;
};

export const getOrderGroupUrl = (groupId, orderId, webInAppConfig) => {
  const searchParams = new URLSearchParams();

  const { origin } = window.location;

  if (webInAppConfig && webInAppConfig.id) {
    searchParams.set('webinapp', webInAppConfig.id);

    if (webInAppConfig.id === WEB_IN_APP_MOMO_ID) {
      searchParams.set('refId', webInAppConfig.refId);
      //* Only for dev tool (Testing environment)
      // searchParams.set('appId', webInAppConfig.appId);
    }

    if (webInAppConfig.id === WEB_IN_APP_VIETTELPAY_ID) {
      searchParams.set('activate_type', webInAppConfig.activate_type);
      searchParams.set('msisdn', webInAppConfig.msisdn);
      searchParams.set('time', webInAppConfig.time);
      searchParams.set('check_sum', webInAppConfig.check_sum);
      searchParams.set('merchant_code', webInAppConfig.merchant_code);
    }
  }

  // Redirect URL: GoShop homepage with webinapp configs
  let redirectUrl = `${origin}?${searchParams.toString()}`;

  // Encode redirectUrl
  if (window.encodeURIComponent) {
    redirectUrl = window.encodeURIComponent(redirectUrl);
  }

  searchParams.set('redirectUrl', redirectUrl);

  let recordUrl = `${
    process.env.NUXT_ENV_BASE_URL
  }/order-groups/${groupId}/${orderId}?${searchParams.toString()}`;

  return recordUrl;
};

// Example: 540 => 09:00 AM
export const formatHourFromMinutes = minutes => {
  const h = Math.floor(minutes / 60);
  const m = minutes % 60;
  const a = h >= 12 ? 'PM' : 'AM';

  const pad = d => {
    return d < 10 ? '0' + d.toString() : d.toString();
  };

  return `${pad(h)}:${pad(m)} ${a}`;
};

export const getTimerValue = time => {
  const todayDeadline = dayjs(time * 1000);

  const space = todayDeadline.unix() - dayjs().unix();

  const date = Math.floor(space / (24 * 3600));
  const hour = Math.floor((space - date * 24 * 3600) / 3600);
  const minute = Math.floor((space - date * 24 * 3600 - hour * 3600) / 60);
  const second = space - date * 3600 * 24 - hour * 3600 - minute * 60;

  return {
    dates: date > 9 ? `${date}` : `0${date}`,
    hours: hour > 9 ? `${hour}` : `0${hour}`,
    minutes: minute > 9 ? `${minute}` : `0${minute}`,
    seconds: second > 9 ? `${second}` : `0${second}`,
  };
};

export const formatAndHidePhoneNumber = phone => {
  var cleaned = ('' + phone).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{3})$/);
  if (match) {
    var intlCode = match[1] ? '0' : '';
    return [intlCode, match[2], '***', match[4]].join('');
  }
  return null;
};
