import { asyncSliceCreator, asyncDynamicSliceCreator } from '@/utils';
import * as storeAPI from '@/api/store';
import { convertStoreList, convertStore } from '@/plugins/utils';

const [nearByStoresSlice, { name: nearByStoresSliceName }] = asyncSliceCreator(
  'nearByStores',
  storeAPI.listStore,
);

const [nearByStoresByTagIdSlice, { name: nearByStoresByTagIdSliceName }] = asyncDynamicSliceCreator(
  'nearByStoresByTagId',
  ({ params }) => storeAPI.listStore(params),
);

const [storeByIdSlice, { name: storeByIdSliceName, types: storeByIdSliceTypes }] = asyncDynamicSliceCreator(
  'storeById',
  ({ params }) => storeAPI.storeDetail(params),
);

const [storesByBrandNameSlice, { name: storesByBrandNameSliceName }] = asyncDynamicSliceCreator(
  'storesByBrandName',
  ({ params }) => storeAPI.getStoreBrands(params),
);

export { storeByIdSliceTypes };

export default {
  state: () => ({
    ...nearByStoresSlice.state(),
    ...nearByStoresByTagIdSlice.state(),
    ...storeByIdSlice.state(),
    ...storesByBrandNameSlice.state(),
  }),
  mutations: {
    ...nearByStoresSlice.mutations,
    ...nearByStoresByTagIdSlice.mutations,
    ...storeByIdSlice.mutations,
    ...storesByBrandNameSlice.mutations,
  },
  actions: {
    ...nearByStoresSlice.actions,
    ...nearByStoresByTagIdSlice.actions,
    ...storeByIdSlice.actions,
    ...storesByBrandNameSlice.actions,
  },
  getters: {
    ...nearByStoresSlice.getters,
    ...nearByStoresByTagIdSlice.getters,
    ...storeByIdSlice.getters,
    ...storesByBrandNameSlice.getters,
    homePageNearByStores: state => convertStoreList(state[nearByStoresSliceName].data ?? []),
    nearByStoresByTagId: state => tagId =>
      convertStoreList(state[nearByStoresByTagIdSliceName]?.[tagId]?.data ?? []),
    storeById: state => storeId =>
      state[storeByIdSliceName]?.[storeId]?.data?.store
        ? {
            ...state[storeByIdSliceName][storeId].data,
            store: convertStore(state[storeByIdSliceName][storeId].data.store),
          }
        : {},
    storesByBrandName: state => brandName =>
      convertStoreList(state[storesByBrandNameSliceName]?.[brandName]?.data ?? []),
    // listItems: state => storeId =>
    // state[storeByIdSliceName]?.[storeId]?.data?.store
    //   ? {
    //       ...state[storeByIdSliceName][storeId].data,
    //       store: convertStore(state[storeByIdSliceName][storeId].data.store),
    //     }
    //   : {},
  },
};
