import { getToken } from '@/plugins/utils';

export const types = {
  INIT: 'auth/init',
};

export default {
  state: () => ({
    accessToken: null,
  }),
  mutations: {
    [types.INIT](state, accessToken) {
      state.accessToken = accessToken;
    },
  },
  actions: {
    initAuth({ commit }) {
      const accessToken = getToken();

      if (accessToken) commit(types.INIT, accessToken);
    },
  },
  getters: {
    authenticated: state => Boolean(state.accessToken),
  },
};
