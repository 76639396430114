export {
  LinkOutline,
  CloseOutline,
  ShoppingCartOutline,
  EditOutline,
  DeleteOutline,
  UserOutline,
  PhoneOutline,
  MinusOutline,
  PlusOutline,
  TagOutline,
  TagTwoTone,
  EnvironmentOutline,
  EnvironmentFill,
  MailFill,
  PhoneFill,
  CheckCircleFill,
  RightOutline,
  ArrowLeftOutline,
  FlagOutline,
  InfoCircleOutline,
  InfoCircleFill,
  ClockCircleOutline,
  LoadingOutline,
  CloseCircleOutline,
  CloseCircleFill,
  DownOutline,
  SearchOutline,
  CheckOutline,
  CopyOutline,
  ShoppingOutline,
  ShareAltOutline,
  RedoOutline,
  TagsTwoTone,
  FileTextOutline,
} from '@ant-design/icons';
