//
//
//
//
//
//

import { Col as AntCol } from 'ant-design-vue';

export default {
  components: {
    AntCol,
  },
};
