//
//
//
//
//
//

export default {
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  methods: {
    withPartnerPath(to) {
      let path = to;
      const webInAppConfig = this.$store.state.webInAppConfig;
      if (webInAppConfig) {
        path = `/${webInAppConfig.id}${to}`;
      }
      return path;
    },
  },
};
