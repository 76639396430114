export const EVENT_HIDE_TOOLBAR = 'hide_toolbar';
export const EVENT_CLOSE = 'close';
export const EVENT_BACK = 'back';
export const EVENT_OPEN_PAGE = 'openPage';
export const EVENT_SHARE = 'screenshot_share';

export async function postMessage(eventType) {
  try {
    if (window.webkit) {
      return window.webkit.messageHandlers.requestAction.postMessage(eventType);
    } else if (typeof WebJSInterface !== 'undefined') {
      return WebJSInterface.requestAction(JSON.stringify({ name: eventType }));
    }
    throw new Error('Not found device type to emit event');
  } catch (error) {
    throw error;
  }
}

export async function postMessageHaveBody(json) {
  try {
    if (window.webkit) {
      return window.webkit.messageHandlers.requestAction.postMessage(json);
    } else if (typeof WebJSInterface !== 'undefined') {
      return WebJSInterface.requestAction(json);
    }
    throw new Error('Not found device type to emit event');
  } catch (error) {
    throw error;
  }
}
