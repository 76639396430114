import remoteConfig from './remoteConfig';
import tag from './tag';
import store from './store';
import cart from './cart';
import ux from './ux';
import promotion from './promotion';
import order from './order';
import user from './user';
import deliveryInfo from './deliveryInfo';
import auth from './auth';
import dealItemGroup from './dealItemGroup';
import groups from './groups';

export default {
  namespaced: true,
  modules: {
    remoteConfig,
    tag,
    store,
    cart,
    ux,
    promotion,
    order,
    user,
    deliveryInfo,
    auth,
    dealItemGroup,
    groups,
  },
};
