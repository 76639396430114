//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { PortalTarget } from 'portal-vue';

export default {
  name: 'DefaultV2',
  components: {
    PortalTarget,
  },
  data: () => ({
    include: [],
  }),
};
