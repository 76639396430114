//
//
//
//
//
//

export default {
  props: {
    className: String,
  },
};
