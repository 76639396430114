export const BaseAlert = () => import('../../components/base/Alert/Alert.vue' /* webpackChunkName: "components/base-alert" */).then(c => wrapFunctional(c.default || c))
export const BaseAvatar = () => import('../../components/base/Avatar/Avatar.vue' /* webpackChunkName: "components/base-avatar" */).then(c => wrapFunctional(c.default || c))
export const BaseBadge = () => import('../../components/base/Badge/Badge.vue' /* webpackChunkName: "components/base-badge" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/base/Button/Button.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseCard = () => import('../../components/base/Card/Card.vue' /* webpackChunkName: "components/base-card" */).then(c => wrapFunctional(c.default || c))
export const BaseCardGrid = () => import('../../components/base/Card/Grid.vue' /* webpackChunkName: "components/base-card-grid" */).then(c => wrapFunctional(c.default || c))
export const BaseCardMeta = () => import('../../components/base/Card/Meta.vue' /* webpackChunkName: "components/base-card-meta" */).then(c => wrapFunctional(c.default || c))
export const BaseCheckbox = () => import('../../components/base/Checkbox/Checkbox.vue' /* webpackChunkName: "components/base-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseCheckboxGroup = () => import('../../components/base/Checkbox/Group.vue' /* webpackChunkName: "components/base-checkbox-group" */).then(c => wrapFunctional(c.default || c))
export const BaseCol = () => import('../../components/base/Col/Col.vue' /* webpackChunkName: "components/base-col" */).then(c => wrapFunctional(c.default || c))
export const BaseDivider = () => import('../../components/base/Divider/Divider.vue' /* webpackChunkName: "components/base-divider" */).then(c => wrapFunctional(c.default || c))
export const BaseDrawer = () => import('../../components/base/Drawer/Drawer.vue' /* webpackChunkName: "components/base-drawer" */).then(c => wrapFunctional(c.default || c))
export const BaseForm = () => import('../../components/base/Form/Form.vue' /* webpackChunkName: "components/base-form" */).then(c => wrapFunctional(c.default || c))
export const BaseFormItem = () => import('../../components/base/Form/Item.vue' /* webpackChunkName: "components/base-form-item" */).then(c => wrapFunctional(c.default || c))
export const BaseIcon = () => import('../../components/base/Icon/Icon.vue' /* webpackChunkName: "components/base-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseImage = () => import('../../components/base/Image/Image.vue' /* webpackChunkName: "components/base-image" */).then(c => wrapFunctional(c.default || c))
export const BaseInput = () => import('../../components/base/Input/Input.vue' /* webpackChunkName: "components/base-input" */).then(c => wrapFunctional(c.default || c))
export const BaseLayoutContent = () => import('../../components/base/Layout/Content.vue' /* webpackChunkName: "components/base-layout-content" */).then(c => wrapFunctional(c.default || c))
export const BaseLayoutFooter = () => import('../../components/base/Layout/Footer.vue' /* webpackChunkName: "components/base-layout-footer" */).then(c => wrapFunctional(c.default || c))
export const BaseLayoutHeader = () => import('../../components/base/Layout/Header.vue' /* webpackChunkName: "components/base-layout-header" */).then(c => wrapFunctional(c.default || c))
export const BaseLayout = () => import('../../components/base/Layout/Layout.vue' /* webpackChunkName: "components/base-layout" */).then(c => wrapFunctional(c.default || c))
export const BaseLink = () => import('../../components/base/Link/Link.vue' /* webpackChunkName: "components/base-link" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../components/base/Modal/Modal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseRadioGroup = () => import('../../components/base/Radio/Group.vue' /* webpackChunkName: "components/base-radio-group" */).then(c => wrapFunctional(c.default || c))
export const BaseRadio = () => import('../../components/base/Radio/Radio.vue' /* webpackChunkName: "components/base-radio" */).then(c => wrapFunctional(c.default || c))
export const BaseResponsiveModal = () => import('../../components/base/ResponsiveModal/ResponsiveModal.vue' /* webpackChunkName: "components/base-responsive-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseRow = () => import('../../components/base/Row/Row.vue' /* webpackChunkName: "components/base-row" */).then(c => wrapFunctional(c.default || c))
export const BaseSelectOption = () => import('../../components/base/Select/Option.vue' /* webpackChunkName: "components/base-select-option" */).then(c => wrapFunctional(c.default || c))
export const BaseSelect = () => import('../../components/base/Select/Select.vue' /* webpackChunkName: "components/base-select" */).then(c => wrapFunctional(c.default || c))
export const BaseSpace = () => import('../../components/base/Space/Space.vue' /* webpackChunkName: "components/base-space" */).then(c => wrapFunctional(c.default || c))
export const BaseSpin = () => import('../../components/base/Spin/Spin.vue' /* webpackChunkName: "components/base-spin" */).then(c => wrapFunctional(c.default || c))
export const BaseSwiperSlide = () => import('../../components/base/Swiper/Slide.vue' /* webpackChunkName: "components/base-swiper-slide" */).then(c => wrapFunctional(c.default || c))
export const BaseSwiper = () => import('../../components/base/Swiper/Swiper.vue' /* webpackChunkName: "components/base-swiper" */).then(c => wrapFunctional(c.default || c))
export const BaseTag = () => import('../../components/base/Tag/Tag.vue' /* webpackChunkName: "components/base-tag" */).then(c => wrapFunctional(c.default || c))
export const BaseTooltip = () => import('../../components/base/Tooltip/Tooltip.vue' /* webpackChunkName: "components/base-tooltip" */).then(c => wrapFunctional(c.default || c))
export const BaseTypographyLink = () => import('../../components/base/Typography/Link.vue' /* webpackChunkName: "components/base-typography-link" */).then(c => wrapFunctional(c.default || c))
export const BaseTypographyParagraph = () => import('../../components/base/Typography/Paragraph.vue' /* webpackChunkName: "components/base-typography-paragraph" */).then(c => wrapFunctional(c.default || c))
export const BaseTypographyText = () => import('../../components/base/Typography/Text.vue' /* webpackChunkName: "components/base-typography-text" */).then(c => wrapFunctional(c.default || c))
export const BaseTypographyTitle = () => import('../../components/base/Typography/Title.vue' /* webpackChunkName: "components/base-typography-title" */).then(c => wrapFunctional(c.default || c))
export const SharedBannerSwiper = () => import('../../components/shared/BannerSwiper/BannerSwiper.vue' /* webpackChunkName: "components/shared-banner-swiper" */).then(c => wrapFunctional(c.default || c))
export const SharedCartDetail = () => import('../../components/shared/CartDetail/CartDetail.vue' /* webpackChunkName: "components/shared-cart-detail" */).then(c => wrapFunctional(c.default || c))
export const SharedCartList = () => import('../../components/shared/CartList/CartList.vue' /* webpackChunkName: "components/shared-cart-list" */).then(c => wrapFunctional(c.default || c))
export const SharedCartListItem = () => import('../../components/shared/CartList/Item.vue' /* webpackChunkName: "components/shared-cart-list-item" */).then(c => wrapFunctional(c.default || c))
export const SharedClosedStore = () => import('../../components/shared/ClosedStore/ClosedStore.vue' /* webpackChunkName: "components/shared-closed-store" */).then(c => wrapFunctional(c.default || c))
export const SharedConfirmScheduleOrder = () => import('../../components/shared/ConfirmScheduleOrder/ConfirmScheduleOrder.vue' /* webpackChunkName: "components/shared-confirm-schedule-order" */).then(c => wrapFunctional(c.default || c))
export const SharedCouponContent = () => import('../../components/shared/Coupon/Content.vue' /* webpackChunkName: "components/shared-coupon-content" */).then(c => wrapFunctional(c.default || c))
export const SharedCouponDetail = () => import('../../components/shared/Coupon/Detail.vue' /* webpackChunkName: "components/shared-coupon-detail" */).then(c => wrapFunctional(c.default || c))
export const SharedCouponDetailContent = () => import('../../components/shared/Coupon/DetailContent.vue' /* webpackChunkName: "components/shared-coupon-detail-content" */).then(c => wrapFunctional(c.default || c))
export const SharedCouponItem = () => import('../../components/shared/Coupon/Item.vue' /* webpackChunkName: "components/shared-coupon-item" */).then(c => wrapFunctional(c.default || c))
export const SharedCouponList = () => import('../../components/shared/Coupon/List.vue' /* webpackChunkName: "components/shared-coupon-list" */).then(c => wrapFunctional(c.default || c))
export const SharedCouponStoreList = () => import('../../components/shared/Coupon/StoreList.vue' /* webpackChunkName: "components/shared-coupon-store-list" */).then(c => wrapFunctional(c.default || c))
export const SharedDealItemGroup = () => import('../../components/shared/DealItemGroup/DealItemGroup.vue' /* webpackChunkName: "components/shared-deal-item-group" */).then(c => wrapFunctional(c.default || c))
export const SharedDealItemGroupHorizontal = () => import('../../components/shared/DealItemGroup/Horizontal.vue' /* webpackChunkName: "components/shared-deal-item-group-horizontal" */).then(c => wrapFunctional(c.default || c))
export const SharedDeliveryInfoAddressInput = () => import('../../components/shared/DeliveryInfo/AddressInput.vue' /* webpackChunkName: "components/shared-delivery-info-address-input" */).then(c => wrapFunctional(c.default || c))
export const SharedDeliveryInfoContent = () => import('../../components/shared/DeliveryInfo/Content.vue' /* webpackChunkName: "components/shared-delivery-info-content" */).then(c => wrapFunctional(c.default || c))
export const SharedDeliveryInfo = () => import('../../components/shared/DeliveryInfo/DeliveryInfo.vue' /* webpackChunkName: "components/shared-delivery-info" */).then(c => wrapFunctional(c.default || c))
export const SharedGroups = () => import('../../components/shared/Groups/Groups.vue' /* webpackChunkName: "components/shared-groups" */).then(c => wrapFunctional(c.default || c))
export const SharedGroupsModal = () => import('../../components/shared/Groups/Modal.vue' /* webpackChunkName: "components/shared-groups-modal" */).then(c => wrapFunctional(c.default || c))
export const SharedGroupsOrder = () => import('../../components/shared/Groups/Order.vue' /* webpackChunkName: "components/shared-groups-order" */).then(c => wrapFunctional(c.default || c))
export const SharedGroupsPricingTiers = () => import('../../components/shared/Groups/PricingTiers.vue' /* webpackChunkName: "components/shared-groups-pricing-tiers" */).then(c => wrapFunctional(c.default || c))
export const SharedMenuCategory = () => import('../../components/shared/Menu/Category.vue' /* webpackChunkName: "components/shared-menu-category" */).then(c => wrapFunctional(c.default || c))
export const SharedMenuItem = () => import('../../components/shared/Menu/Item.vue' /* webpackChunkName: "components/shared-menu-item" */).then(c => wrapFunctional(c.default || c))
export const SharedMenu = () => import('../../components/shared/Menu/Menu.vue' /* webpackChunkName: "components/shared-menu" */).then(c => wrapFunctional(c.default || c))
export const SharedMenuSkeleton = () => import('../../components/shared/Menu/Skeleton.vue' /* webpackChunkName: "components/shared-menu-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SharedPageFooter = () => import('../../components/shared/PageFooter/PageFooter.vue' /* webpackChunkName: "components/shared-page-footer" */).then(c => wrapFunctional(c.default || c))
export const SharedPaymentMethodContent = () => import('../../components/shared/PaymentMethod/Content.vue' /* webpackChunkName: "components/shared-payment-method-content" */).then(c => wrapFunctional(c.default || c))
export const SharedPaymentMethodList = () => import('../../components/shared/PaymentMethod/List.vue' /* webpackChunkName: "components/shared-payment-method-list" */).then(c => wrapFunctional(c.default || c))
export const SharedProductDetailContent = () => import('../../components/shared/ProductDetail/Content.vue' /* webpackChunkName: "components/shared-product-detail-content" */).then(c => wrapFunctional(c.default || c))
export const SharedProductDetailCustomization = () => import('../../components/shared/ProductDetail/Customization.vue' /* webpackChunkName: "components/shared-product-detail-customization" */).then(c => wrapFunctional(c.default || c))
export const SharedProductDetail = () => import('../../components/shared/ProductDetail/ProductDetail.vue' /* webpackChunkName: "components/shared-product-detail" */).then(c => wrapFunctional(c.default || c))
export const SharedRequireAuth = () => import('../../components/shared/RequireAuth/RequireAuth.vue' /* webpackChunkName: "components/shared-require-auth" */).then(c => wrapFunctional(c.default || c))
export const SharedScheduleOrderContent = () => import('../../components/shared/ScheduleOrder/Content.vue' /* webpackChunkName: "components/shared-schedule-order-content" */).then(c => wrapFunctional(c.default || c))
export const SharedScheduleOrderIntro = () => import('../../components/shared/ScheduleOrder/Intro.vue' /* webpackChunkName: "components/shared-schedule-order-intro" */).then(c => wrapFunctional(c.default || c))
export const SharedScheduleOrder = () => import('../../components/shared/ScheduleOrder/ScheduleOrder.vue' /* webpackChunkName: "components/shared-schedule-order" */).then(c => wrapFunctional(c.default || c))
export const SharedSearchList = () => import('../../components/shared/SearchList/SearchList.vue' /* webpackChunkName: "components/shared-search-list" */).then(c => wrapFunctional(c.default || c))
export const SharedSearchListSkeleton = () => import('../../components/shared/SearchList/Skeleton.vue' /* webpackChunkName: "components/shared-search-list-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SharedServiceRequestInfo = () => import('../../components/shared/ServiceRequestInfo/ServiceRequestInfo.vue' /* webpackChunkName: "components/shared-service-request-info" */).then(c => wrapFunctional(c.default || c))
export const SharedStoreFrameDealItemFrame = () => import('../../components/shared/StoreFrame/DealItemFrame.vue' /* webpackChunkName: "components/shared-store-frame-deal-item-frame" */).then(c => wrapFunctional(c.default || c))
export const SharedStoreFrame = () => import('../../components/shared/StoreFrame/StoreFrame.vue' /* webpackChunkName: "components/shared-store-frame" */).then(c => wrapFunctional(c.default || c))
export const SharedStoreInfo = () => import('../../components/shared/StoreInfo/StoreInfo.vue' /* webpackChunkName: "components/shared-store-info" */).then(c => wrapFunctional(c.default || c))
export const SharedStoreListDealItemGroup = () => import('../../components/shared/StoreList/DealItemGroup.vue' /* webpackChunkName: "components/shared-store-list-deal-item-group" */).then(c => wrapFunctional(c.default || c))
export const SharedStoreListHorizontal = () => import('../../components/shared/StoreList/Horizontal.vue' /* webpackChunkName: "components/shared-store-list-horizontal" */).then(c => wrapFunctional(c.default || c))
export const SharedStoreList = () => import('../../components/shared/StoreList/StoreList.vue' /* webpackChunkName: "components/shared-store-list" */).then(c => wrapFunctional(c.default || c))
export const SharedSwitchStoreConfirm = () => import('../../components/shared/SwitchStoreConfirm/SwitchStoreConfirm.vue' /* webpackChunkName: "components/shared-switch-store-confirm" */).then(c => wrapFunctional(c.default || c))
export const SharedTagList = () => import('../../components/shared/TagList/TagList.vue' /* webpackChunkName: "components/shared-tag-list" */).then(c => wrapFunctional(c.default || c))
export const SharedTemporarilyClosedStore = () => import('../../components/shared/TemporarilyClosedStore/TemporarilyClosedStore.vue' /* webpackChunkName: "components/shared-temporarily-closed-store" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
