
import { Icon as AntIcon } from 'ant-design-vue';

import PrecisionIcon from '@/assets/svg/icons/precision.svg?inline';
import AhaMoveIcon from '@/assets/svg/icons/aha-move.svg?inline';
import ShareArrow from '@/assets/svg/icons/share-arrow.svg?inline';
import Search from '@/assets/svg/icons/Search1.svg?inline';

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  render(createElement) {
    const props = { ...this.$attrs };

    let component;
    if (this.$props.type === 'precision') {
      component = PrecisionIcon;
    } else if (this.$props.type === 'aha-move') {
      component = AhaMoveIcon;
    } else if (this.$props.type === 'share-arrow') {
      component = ShareArrow;
    } else if (this.$props.type === 'search') {
      component = Search;
    }

    if (!!component) {
      props.component = component;
    } else {
      props.type = this.$props.type;
    }

    return createElement(AntIcon, {
      props,
      on: this.$listeners,
    });
  },
};
