module.exports = {
  // sumBy: require('lodash/fp/sumBy'),
  // clone: require('lodash/clone'),
  cloneDeep: require('lodash/cloneDeep'),
  groupBy: require('lodash/groupBy'),
  // sortBy: require('lodash/sortBy'),
  camelCase: require('lodash/camelCase'),
  // filter: require('lodash/filter'),
  // findIndex: require('lodash/findIndex'),
  // find: require('lodash/find'),
  orderBy: require('lodash/orderBy'),
  // indexOf: require('lodash/indexOf'),
  // assign: require('lodash/assign'),
  // countBy: require('lodash/countBy'),
  // forEach: require('lodash/forEach'),
  // pick: require('lodash/pick'),
  minBy: require('lodash/minBy'),
  maxBy: require('lodash/maxBy'),
  debounce: require('lodash/debounce'),
  // pickBy: require('lodash/pickBy'),
  // sortedUniq: require('lodash/sortedUniq'),
  // omit: require('lodash/omit'),
  // intersection: require('lodash/intersection'),
  // findKey: require('lodash/findKey'),
  // toNumber: require('lodash/toNumber'),
  isNumber: require('lodash/isNumber'),
  isNil: require('lodash/isNil'),
  // isNaN: require('lodash/isNaN'),
  // take: require('lodash/take'),
  // isEqual: require('lodash/isEqual'),
  // differenceWith: require('lodash/differenceWith'),
  // uniqBy: require('lodash/uniqBy'),
  // toPairs: require('lodash/toPairs'),
  // fromPairs: require('lodash/fromPairs')
  set: require('lodash/set'),
};
