import { storeToken, getCookie } from '@/plugins/utils';

export default async ({ query, store }) => {
  // TODO: Re-implement auth flow
  storeToken(getCookie('token'));

  const { dispatch } = store;
  const { token } = query;
  if (token) storeToken(token);

  dispatch('v2/initAuth');
};
