import { asyncSliceCreator } from '@/utils';
import { userProfile } from '@/api/order';

const [userProfileSlice, { name: userProfileSliceName }] = asyncSliceCreator('userProfile', userProfile);

export default {
  state: () => ({
    ...userProfileSlice.state(),
  }),
  mutations: {
    ...userProfileSlice.mutations,
  },
  actions: {
    ...userProfileSlice.actions,
  },
  getters: {
    ...userProfileSlice.getters,
    userBalance: state => {
      const { bonus_account = {}, main_account = {} } = state[userProfileSliceName]?.data ?? {};

      return (bonus_account.VND ?? 0) + (main_account.VND ?? 0);
    },
    userProfile: state => ({
      name: state[userProfileSliceName].data?.name,
      phone: state[userProfileSliceName].data?._id,
    }),
  },
};
