const copyToClipboard = async value => {
  if (typeof value !== 'string') return;
  try {
    await navigator.clipboard.writeText(value);
  } catch (error) {
    const textarea = document.createElement('textarea');
    textarea.setAttribute('readonly', true);
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
    textarea.textContent = value;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }
};

export default copyToClipboard;
