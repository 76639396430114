import Vuex from 'vuex';
import * as getters from './getters';
import * as types from './mutation-types';
import v2 from './modules/v2';

const debug = process.env.NODE_ENV !== 'production';

const createStore = () => {
  return new Vuex.Store({
    state: () => ({
      token: null,
      requiredAuthModal: false,
      userProfile: {},
      webInAppConfig: null,
    }),
    getters,
    actions: {
      setToken({ commit }, token) {
        commit(types.SET_TOKEN, token);
      },
      setUserProfile({ commit }, info) {
        commit(types.SET_USER_PROFILE, info);
      },
      setRequiredAuthModal({ commit }, value) {
        commit(types.SET_REQUIRED_AUTH_MODAL, value);
      },
      setWebInAppConfig({ commit }, value) {
        commit(types.SET_WEB_IN_APP_CONFIG, value);
      },
    },
    mutations: {
      [types.SET_TOKEN](state, value) {
        state.token = value;
      },
      [types.SET_USER_PROFILE](state, info) {
        state.userProfile = info;
      },
      [types.SET_REQUIRED_AUTH_MODAL](state, value) {
        state.requiredAuthModal = value;
      },
      [types.SET_WEB_IN_APP_CONFIG](state, value) {
        state.webInAppConfig = value;
      },
    },
    modules: Object.assign({
      v2,
    }),
    strict: debug,
  });
};

export default createStore;
