import dayjs from 'dayjs';
import { asyncSliceCreator } from '@/utils';
import { getPromoList } from '@/api/order';

const [promotionsSlice, { name: promotionsSliceName }] = asyncSliceCreator('promotions', getPromoList);

export default {
  state: () => ({
    ...promotionsSlice.state(),
  }),
  mutations: {
    ...promotionsSlice.mutations,
  },
  actions: {
    ...promotionsSlice.actions,
  },
  getters: {
    ...promotionsSlice.getters,
    promotions: state =>
      state[promotionsSliceName].data?.map(promo => ({
        ...promo,
        formattedStartDate: dayjs.unix(promo.start_time).format('DD/MM/YYYY'),
        formattedExpiryDate: dayjs.unix(promo.end_time).format('DD/MM/YYYY'),
      })) ?? [],
  },
};
