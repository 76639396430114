export const EVENT = {
  SEARCH_ADDRESS_ERROR: 'Checkout_search_address_error',
  ESTIMATE_FEE_ERROR: 'Checkout_estimate_fee_error',
  NOT_VALID_PROMO_CODE: 'Checkout_not_valid_promo_code',
  SHOPPING_SEARCH_LOCATION: 'Shopping_search_location',
  SHOPPING_HOME_PAGE_SEARCH_LOCATION: 'Shopping_homepage_search_location',
  SHOPPING_HOME_CATEGORY_SELECT_STORE: 'Shopping_homepage_category_select_store',
  SHOPPING_SEARCH_ITEM_IN_STORE: 'Shopping_search_item_in_store',
  SHOPPING_VIEW_INFO_STORE: 'Shopping_view_info_store',
  SHOPPING_SHARING_STORE: 'Shopping_sharing_store',
  SHOPPING_CHECK_REWARDS_IN_STORE: 'Shopping_check_rewards_in_store',
  SHOPPING_SELECT_LOCATION: 'Shopping_select_location',
  SHOPPING_SELECT_LOCATION_FAIL: 'Shopping_select_location_fail',
  SHOPPING_ADD_RECIPIENT_INFO: 'Shopping_add_recipient_info',
  SHOPPING_SELECT_STORE: 'Shopping_select_store',
  SHOPPING_COPY_PROMO_CODE: 'Shopping_copy_promo_code',
  SHOPPING_SELECT_ITEM_TYPE: 'Shopping_select_item_type',
  SHOPPING_SELECT_ITEM: 'Shopping_select_item',
  SHOPPING_SELECT_CUSTOMIZATION: 'Shopping_select_customization',
  SHOPPING_SELECT_QUANTITY: 'Shopping_select_quantity',
  SHOPPING_ADD_TO_CART: 'Shopping_add_to_cart',
  SHOPPING_ADD_TO_CART_FAIL: 'Shopping_add_to_cart_fail',
  SHOPPING_GO_BACK_TO_ITEM_SCREEN: 'Shopping_go_back_to_item_screen',
  SHOPPING_ADD_NEW_CART: 'Shopping_add_new_cart',
  SHOPPING_SELECT_CART: 'Shopping_select_cart',
  SHOPPING_CHANGE_PAYMENT_METHOD: 'Shopping_change_payment_method',
  SHOPPING_SELECT_PAYMENT_METHOD: 'Shopping_select_payment_method',
  SHOPPING_MODIFY_QUANTIY: 'Shopping_modify_quantiy',
  SHOPPING_BACK_TO_ITEM_SCREEN: 'Shopping_back_to_item_screen',
  SHOPPING_ADD_NOTE: 'Shopping_add_note',
  SHOPPING_FIND_PROMO_CODE: 'Shopping_find_promo_code',
  SHOPPING_ADD_PROMO_CODE: 'Shopping_add_promo_code',
  SHOPPING_PROMO_CODE_USE_LATER: 'Shopping_promo_code_use_later',
  SHOPPING_BOOK: 'Shopping_book',
  SHOPPING_SCHEDULE_BOOKING: 'Shopping_schedule_booking',
  SHOPPING_ORDER_CREATED: 'Shopping_order_created',
  SHOPPING_ORDER_CREATED_FAIL: 'Shopping_order_created_fail',
  SHOPPING_SELECT_COUPON_IN_STORE: 'Shopping_select_coupon_in_store',
  SHOPPING_NEW: 'Shopping_new', // Meaning: User chưa từng tạo đơn với AhaMove và hoàn thành đơn dịch vụ shopping (AhaMart)
  SHOPPING_NEW_1: 'Shopping_new_1', // Meaning: User chưa từng tạo đơn dịch vụ AhaMart nhưng đã từng tạo đơn với dịch vụ khác và hoàn thành đơn dịch vụ AhaMart
  SHOPPING_OLD: 'Shopping_old', // Meaning: User đã từng tạo đơn với Ahamove và hoàn thành đơn dịch vụ shopping (AhaMart)
};

export const ACTION = {
  CLICK: 'click',
  SCROLL: 'scroll',
  NON_INTERACTION: 'non_interaction',
};

let gtm;
let _store = {};

export const trackingEvent = (name, props = {}) => {
  if (!gtm) return;

  const userProfile = (_store && _store.state && _store.state.userProfile) || {};

  const value = {
    action: ACTION.CLICK,
    user_id: userProfile.mobile,
    // user_name: userProfile.name,
    city_id: userProfile.city,
    ...props,
  };

  gtm.push({
    event: 'custom_event',
    action: props.action || ACTION.CLICK,
    name: name,
    value: value,
  });
};

export default function (payload) {
  const { $gtm, store } = payload;

  _store = store;

  $gtm.init(process.env.NUXT_ENV_GOOGLE_TAG_MANAGER_ID);

  gtm = $gtm;
}
