import { camelCase } from '@/plugins/lodash';
import Vue from 'vue';

const asyncDynamicSliceCreator = (name, asyncFetcher, options = {}) => {
  const { transformResponse = res => res.data } = options;

  const types = {
    REQUEST: `${name}/request`,
    SUCCESS: `${name}/success`,
    FAILURE: `${name}/failure`,
  };

  const slice = {
    state: () => ({
      [name]: {},
    }),
    mutations: {
      [types.REQUEST](state, { id }) {
        if (!!state[name][`${id}`]) {
          state[name][`${id}`].isFetching = true;
        } else {
          Vue.set(state[name], `${id}`, {
            isFetching: true,
            data: null,
            error: null,
          });
        }
      },
      [types.SUCCESS](state, { id, data }) {
        if (!!state[name][`${id}`]) {
          state[name][`${id}`].isFetching = false;
          state[name][`${id}`].data = data;
          state[name][`${id}`].error = null;
        } else {
          Vue.set(state[name], `${id}`, {
            isFetching: false,
            data: data,
            error: null,
          });
        }
      },
      [types.FAILURE](state, { id, error }) {
        state[name][`${id}`].isFetching = false;
        state[name][`${id}`].error = error;
      },
    },
    actions: {
      async [camelCase(`get-${name}`)](context, { id, params }) {
        const { commit } = context;

        if (!id) {
          throw Error('asyncDynamicSliceCreator: Missing `id` property in action payload.');
        }

        try {
          commit(types.REQUEST, { id });

          const response = await asyncFetcher({ id, params }, context);

          const transformedResponse = transformResponse(response);

          commit(types.SUCCESS, { id, data: transformedResponse });

          return transformedResponse;
        } catch (error) {
          commit(types.FAILURE, {
            id,
            error: {
              message: error?.message,
              stack: error?.stack,
              name: error?.name,
              code: error?.code,
            },
          });

          throw error;
        }
      },
    },
    getters: {
      [camelCase(`is-${name}-fetching`)]: state => id => state[name]?.[id]?.isFetching,
      [camelCase(`is-${name}-loading`)]: state => id =>
        state[name]?.[id]?.isFetching && !state[name]?.[id]?.data,
      [camelCase(`is-${name}-error`)]: state => id => Boolean(state[name]?.[id]?.error),
      [camelCase(`${name}-error`)]: state => id => state[name]?.[id]?.error,
    },
  };

  return [slice, { name, types }];
};

export default asyncDynamicSliceCreator;
