export const SET_LOCATION = 'SET_LOCATION';
export const GEOCODING = 'GEOCODING';
export const PERMISSION_DENIED = 'PERMISSION_DENIED';
export const SET_ACTIVE_LOCATION_FORM = 'SET_ACTIVE_LOCATION_FORM';
export const SET_FORCE_GET_CURRENT_POSITION = 'SET_FORCE_GET_CURRENT_POSITION';
export const SHOW_LOCATION_CHANGE_CONFIRM = 'SHOW_LOCATION_CHANGE_CONFIRM';

export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_TOKEN = 'SET_TOKEN';
export const SIGNOUT = 'SIGNOUT';
export const SIGNIN = 'SIGNIN';
export const SET_FIREBASE_TOKEN = 'SET_FIREBASE_TOKEN';

export const LOADING_BRANDS = 'LOADING_BRANDS';
export const SET_BRANDS = 'SET_BRANDS';
export const LOADING_STORES_BY_BRAND = 'LOADING_STORES_BY_BRAND';
export const SET_STORES_BY_BRAND = 'SET_STORES_BY_BRAND';
export const RESET_LOADING_BRAND_DETAIL = 'RESET_LOADING_BRAND_DETAIL';

export const SET_LANG = 'SET_LANG';

export const ADD_TO_CART = 'ADD_TO_CART';

export const FROM_LOCATION = 'FROM_LOCATION';
export const ADD_MORE_STORES = 'ADD_MORE_STORES';
export const CHECK_OPENING_HOURS = 'CHECK_OPENING_HOURS';
export const RESET_STORE_DETAIL = 'RESET_STORE_DETAIL';
export const SET_INSTANT_SEARCH_ITEM_KEYWORD = 'SET_INSTANT_SEARCH_ITEM_KEYWORD';
export const SET_STORES_FILTER_CATEGORIES = 'SET_STORES_FILTER_CATEGORIES';
export const RESET_STORES_FILTER_CATEGORIES = 'RESET_STORES_FILTER_CATEGORIES';

export const SET_STORES_GROUPED_BY_CATEGORY = 'SET_STORES_GROUPED_BY_CATEGORY';
export const RESET_STORES_GROUPED_BY_CATEGORY = 'RESET_STORES_GROUPED_BY_CATEGORY';

export const RESET_CART = 'RESET_CART';
export const INIT_CART = 'INIT_CART';
export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';
export const UPDATE_ESTIMATED_FEE = 'UPDATE_ESTIMATED_FEE';
export const SET_TOTAL_QUANTITY = 'SET_TOTAL_QUANTITY';
export const SET_PROMO_CODE = 'SET_PROMO_CODE';
export const SET_REMARKS = 'SET_REMARKS';
export const SET_STORE_NOTE = 'SET_STORE_NOTE';
export const SET_SPECIAL_REQUEST = 'SET_SPECIAL_REQUEST';
export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';
export const RESET_DELIVERY_NOTE = 'RESET_DELIVERY_NOTE';
export const RESET_REQUESTS = 'RESET_REQUESTS';
export const SET_SERVICE_TYPE = 'SET_SERVICE_TYPE';
export const SET_VALIDATE_PAYMENT_METHOD = 'SET_VALIDATE_PAYMENT_METHOD';
export const SET_SHOWING_INVALID_METHOD_STATUS = 'SET_SHOWING_INVALID_METHOD_STATUS';
export const IS_MISSING_LOCATION = 'IS_MISSING_LOCATION';
export const IS_MISSING_SERVICE = 'IS_MISSING_SERVICE';
export const IS_MISSING_ITEMS = 'IS_MISSING_ITEMS';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const PRE_ITEM_SET_DATA = 'PRE_ITEM_SET_DATA';
export const PRE_ITEM_INCREASE_QUANTITY = 'PRE_ITEM_INCREASE_QUANTITY';
export const PRE_ITEM_DESCREASE_QUANTITY = 'PRE_ITEM_DESCREASE_QUANTITY';
export const PRE_ITEM_UPDATE_CUSTOMIZATIONS = 'PRE_ITEM_UPDATE_CUSTOMIZATIONS';

export const SERVICE_UNDER_MAINTENANCE = 'SERVICE_UNDER_MAINTENANCE';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_NOTIFICATIONS_BLOCKED = 'SET_NOTIFICATIONS_BLOCKED';
export const SET_LOCATION_BLOCKED = 'SET_LOCATION_BLOCKED';

export const SET_ORDERS_FILTER = 'SET_ORDERS_FILTER';
export const SET_ORDERS = 'SET_ORDERS';
export const UPDATE_ORDER_IN_LIST = 'UPDATE_ORDER_IN_LIST';
export const UPDATE_SELECTED_ORDER = 'UPDATE_SELECTED_ORDER';

export const SET_COUNTRIES = 'SET_COUNTRIES';

export const SET_SERVICES = 'SET_SERVICES';
export const SET_CITIES = 'SET_CITIES';

export const LOADING_CAMPAIGNS = 'LOADING_CAMPAIGNS';
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';

export const SET_RECOMMEND_STORES = 'SET_RECOMMEND_STORES';
export const SET_RECOMMEND_STORES_HAS_NEXT = 'SET_RECOMMEND_STORES_HAS_NEXT';

export const SET_FIREBASE_REMOTE_CONFIG = 'SET_FIREBASE_REMOTE_CONFIG';
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';

export const SET_PAYMENT_DESCRIPTION = 'SET_PAYMENT_DESCRIPTION';

export const SET_TOKEN_EXPIRE_MODAL_VISIBLE = 'SET_TOKEN_EXPIRE_MODAL_VISIBLE';

export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export const SET_SCROLL_OFFSET = 'SET_SCROLL_OFFSET';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const SET_FINGERPRINT = 'SET_FINGERPRINT';

// V2 actions

export const SET_STORES = 'SET_STORES';
export const SET_STORE_DETAIL = 'SET_STORE_DETAIL';
export const LOADING_STORES = 'LOADING_STORES';
export const LOADING_TAGS = 'LOADING_TAGS';
export const SET_TAGS = 'SET_TAGS';
export const LOADING_BANNERS = 'LOADING_BANNERS';
export const SET_BANNERS = 'SET_BANNERS';
export const LOADING_STORES_BY_TAG = 'LOADING_STORES_BY_TAG';
export const SET_STORES_BY_TAG = 'SET_STORES_BY_TAG';
export const RESET_LOADING_TAG_DETAIL = 'RESET_LOADING_TAG_DETAIL';
export const SET_LOADED_HOMEPAGE_CONTENT = 'SET_LOADED_HOMEPAGE_CONTENT';

export const SET_REQUIRED_AUTH_MODAL = 'SET_REQUIRED_AUTH_MODAL';
export const SET_CURRENT_LOCATION_LOADED = 'SET_CURRENT_LOCATION_LOADED';

export const SET_WEB_IN_APP_CONFIG = 'SET_WEB_IN_APP_CONFIG';

export const CART = {
  UPDATE_CART_ITEM: 'UPDATE_CART_ITEM',
};

export const CHECKOUT = {
  ESTIMATE_FEE_REQUEST: 'ESTIMATE_FEE_REQUEST',
  ESTIMATE_FEE_SUCCESS: 'ESTIMATE_FEE_SUCCESS',
  ESTIMATE_FEE_FAILURE: 'ESTIMATE_FEE_FAILURE',
  CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
  CREATE_ORDER_FAILURE: 'CREATE_ORDER_FAILURE',
  RESET_CHECKOUT: 'RESET_CHECKOUT',
  UPDATE_REMARKS: 'UPDATE_REMARKS',
  UPDATE_STORE_NOTE: 'UPDATE_STORE_NOTE',
  UPDATE_PROMO_CODE: 'UPDATE_PROMO_CODE',
  UPDATE_PAYMENT_METHOD: 'UPDATE_PAYMENT_METHOD',
  UPDATE_PICKUP_POINT: 'UPDATE_PICKUP_POINT',
  UPDATE_DROPOFF_POINT: 'UPDATE_DROPOFF_POINT',
  ERROR_PROMO_CODE_MESSAGE: 'ERROR_PROMO_CODE_MESSAGE',
  REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_ERROR: 'REFRESH_TOKEN_ERROR',
  CLEAR_ESTIMATED_FEE: 'CLEAR_ESTIMATED_FEE',
  UPDATE_STATUS_AUTO_REDEEMED_PROMO_CODE: 'UPDATE_STATUS_AUTO_REDEEMED_PROMO_CODE',
  SET_PROMOTION_LIST: 'SET_PROMOTION_LIST',
  UPDATE_STATUS_GET_PAYMENT_METHODS: 'UPDATE_STATUS_GET_PAYMENT_METHODS',
  SET_PAYMENT_METHODS: 'SET_PAYMENT_METHODS',
  SET_SERVICE_DETAIL: 'SET_SERVICE_DETAIL',
  LOADING_SERVICE_DETAIL: 'LOADING_SERVICE_DETAIL',
  UPDATE_SCHEDULE_ORDER: 'UPDATE_SCHEDULE_ORDER',
  USER_BALANCE: 'USER_BALANCE',
};

export const SEARCH = {
  SET_RECENT_KEYWORDS: 'SET_RECENT_KEYWORDS',
};

export const UX = {
  SET_HOMEPAGE_SCROLL_TOP: 'SET_HOMEPAGE_SCROLL_TOP',
  SET_TAG_SCROLL_TOP: 'SET_TAG_SCROLL_TOP',
  SET_SERVICE_ASSISTANT_SCROLL_TOP: 'SET_SERVICE_ASSISTANT_SCROLL_TOP',
};

export const ASSISTANT = {
  LOADING_ASSISTANT_SERVICE_STORES: 'LOADING_ASSISTANT_SERVICE_STORES',
  SET_ASSISTANT_SERVICE_STORES: 'SET_ASSISTANT_SERVICE_STORES',
  SET_ASSISTANT_SERVICE_CONTENT_LOADED: 'SET_ASSISTANT_SERVICE_CONTENT_LOADED',
};

export const ORDER = {
  GET_ORDER_DETAIL_REQUEST: 'GET_ORDER_DETAIL_REQUEST',
  GET_ORDER_DETAIL_SUCCESS: 'GET_ORDER_DETAIL_SUCCESS',
  GET_ORDER_DETAIL_FAILURE: 'GET_ORDER_DETAIL_FAILURE',
  LOADING_LATEST_ORDER: 'LOADING_LATEST_ORDER',
  SET_LATEST_ORDER: 'SET_LATEST_ORDER',
};
