import { asyncSliceCreator, asyncDynamicSliceCreator } from '@/utils';
import { getOrderPaymentMethods, getServiceDetail } from '@/api/order';
import { PAYMENT_METHODS } from '@/mixins/constances';

const [paymentMethodsSlice, { name: paymentMethodsSliceName }] = asyncSliceCreator(
  'paymentMethods',
  getOrderPaymentMethods,
);

const [serviceDetailSlice, { name: serviceDetailSliceName }] = asyncDynamicSliceCreator(
  'serviceDetail',
  ({ params }) => getServiceDetail(params),
);

export default {
  state: () => ({
    ...paymentMethodsSlice.state(),
    ...serviceDetailSlice.state(),
  }),
  mutations: {
    ...paymentMethodsSlice.mutations,
    ...serviceDetailSlice.mutations,
  },
  actions: {
    ...paymentMethodsSlice.actions,
    ...serviceDetailSlice.actions,
  },
  getters: {
    ...paymentMethodsSlice.getters,
    ...serviceDetailSlice.getters,
    paymentMethods: state =>
      [
        {
          code: PAYMENT_METHODS.CASH_BY_RECIPIENT,
          name_en_us: 'Tiền mặt',
          name_vi_vn: 'Tiền mặt',
          icon_url: require(`~/assets/svg/icons/Cash.svg`),
        },
        ...(state[paymentMethodsSliceName].data?.data?.filter(
          method =>
            ![PAYMENT_METHODS.CASH, PAYMENT_METHODS.CASH_BY_RECIPIENT].includes(method.code.toUpperCase()),
        ) ?? []),
      ].map(method => ({
        ...method,
        code: method.code.toUpperCase(),
      })),
    serviceDetail: state => serviceId => state[serviceDetailSliceName]?.[serviceId]?.data,
    d2dRequestByServiceId: state => serviceId =>
      state[serviceDetailSliceName]?.[serviceId]?.data?.requests.filter(
        ({ delivery_option }) => delivery_option,
      )?.[0],
  },
};
