import Vue from 'vue';
import crypto from 'crypto';
import { isNil, isNumber } from '@/plugins/lodash';
import { PAYMENT_METHODS } from '~/mixins/constances';

export const types = {
  INIT: 'cart/init',
  ADD: 'cart/add',
  INCREASE_QUANTITY: 'cart/increaseQuantity',
  DECREASE_QUANTITY: 'cart/decreaseQuantity',
  UPDATE: 'cart/update',
  REMOVE: 'cart/remove',
  REMOVE_ALL: 'cart/removeAll',
  UPDATE_PAYMENT_METHOD: 'cart/updatePaymentMethod',
  UPDATE_SCHEDULE_ORDER: 'cart/updateScheduleOrder',
};

export const getHashId = ({ _id, selectedCustomizations, note }) =>
  crypto.createHash('md5').update(JSON.stringify({ _id, selectedCustomizations, note })).digest('hex');

export const reduceCartItem = cartItem => {
  let totalPrice = cartItem.price;
  let customizationNames = [];

  let quantity = 1;

  if (cartItem.max_quantity_per_order) {
    quantity = Math.min(cartItem.max_quantity_per_order, cartItem.quantity);
  }

  cartItem?.customizations.forEach(({ _id, options }) => {
    if (!cartItem.selectedCustomizations?.[_id]) return;

    cartItem.selectedCustomizations[_id].forEach(optId => {
      const option = options.find(({ _id }) => optId === _id);

      if (!option) return;

      totalPrice += option.price;
      customizationNames.push(option.name);
    });
  });

  totalPrice *= cartItem.quantity;

  const customizationLabel = customizationNames.join(', ');

  return { ...cartItem, totalPrice, customizationLabel };
};

const CART_STORAGE_KEY = 'cart';
const storeCartData = cartData => {
  localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(cartData));
};

export default {
  state: () => ({
    items: [],
    paymentMethod: PAYMENT_METHODS.CASH_BY_RECIPIENT,
    scheduleOrder: null,
  }),
  mutations: {
    [types.INIT](state, cartData) {
      state.items = cartData;
    },
    [types.ADD](state, item) {
      const hashId = getHashId(item);

      state.items.push({ ...item, hashId });

      storeCartData(state.items);
    },
    [types.INCREASE_QUANTITY](state, hashId) {
      const index = state.items.findIndex(item => item.hashId === hashId);

      state.items[index].quantity += 1;

      storeCartData(state.items);
    },
    [types.DECREASE_QUANTITY](state, hashId) {
      const index = state.items.findIndex(item => item.hashId === hashId);

      state.items[index].quantity -= 1;

      storeCartData(state.items);
    },
    [types.UPDATE](state, { hashId, quantity, selectedCustomizations, note }) {
      const index = state.items.findIndex(item => item.hashId === hashId);

      if (isNumber(quantity)) {
        Vue.set(state.items[index], 'quantity', quantity);
      }
      if (selectedCustomizations) {
        Vue.set(state.items[index], 'selectedCustomizations', selectedCustomizations);
      }
      if (!isNil(note)) {
        Vue.set(state.items[index], 'note', note);
      }

      storeCartData(state.items);
    },
    [types.REMOVE](state, hashId) {
      const index = state.items.findIndex(item => item.hashId === hashId);

      state.items.splice(index, 1);

      storeCartData(state.items);
    },
    [types.REMOVE_ALL](state) {
      state.items = [];

      storeCartData(state.items);
    },
    [types.UPDATE_PAYMENT_METHOD](state, paymentMethodCode) {
      state.paymentMethod = paymentMethodCode;
    },
    [types.UPDATE_SCHEDULE_ORDER](state, payload) {
      state.scheduleOrder = payload;
    },
  },
  actions: {
    initCart({ commit }) {
      try {
        const cart = JSON.parse(localStorage.getItem(CART_STORAGE_KEY) ?? '[]');

        const isVaildCart = cart.map(item => reduceCartItem(item));

        if (isVaildCart) {
          commit(types.INIT, cart);
        } else {
          localStorage.removeItem(CART_STORAGE_KEY);
        }
      } catch (error) {
        localStorage.removeItem(CART_STORAGE_KEY);
      }
    },
    increaseQuantity({ commit, getters }, item) {
      const hashId = item.hashId ?? getHashId(item);

      const hasItem = Boolean(getters.cartItem(hashId));

      if (hasItem) {
        commit(types.INCREASE_QUANTITY, hashId);
      } else {
        commit(types.ADD, { ...item, quantity: 1 });
      }
    },
    decreaseQuantity({ commit, getters }, item) {
      const hashId = item.hashId ?? getHashId(item);

      const cartItem = getters.cartItem(hashId);

      if (cartItem?.quantity >= 2) {
        commit(types.DECREASE_QUANTITY, hashId);
      } else {
        commit(types.REMOVE, hashId);
      }
    },
    updateCartItem({ commit }, item) {
      if (!item.hashId) {
        console.error('Cart item not found');
        return;
      }

      commit(types.UPDATE, item);
    },
    updateOrIncreaseQuantityCartItem({ commit, getters }, item) {
      const hashId = getHashId(item);

      const cartItem = getters.cartItem(hashId);

      if (!!cartItem) {
        // const quantity = cartItem.quantity + item.quantity;
        let quantity = 1;

        if (item.max_quantity_per_order) {
          const totalQuantity = cartItem.quantity + item.quantity;
          quantity = Math.min(item.max_quantity_per_order, totalQuantity);
        }

        commit(types.UPDATE, { ...cartItem, ...item, quantity });
      } else {
        commit(types.ADD, item);
      }
    },
    removeCartItem({ commit }, item) {
      if (!item.hashId) {
        console.error('Cart item not found');
        return;
      }

      commit(types.REMOVE, item.hashId);
    },
    clearCart({ commit }) {
      commit(types.REMOVE_ALL);
    },
    updatePaymentMethod({ commit }, paymentMethodCode) {
      commit(types.UPDATE_PAYMENT_METHOD, paymentMethodCode);
    },
    updateScheduleOrder({ commit }, payload) {
      commit(types.UPDATE_SCHEDULE_ORDER, payload);
    },
  },
  getters: {
    quantityByProductId: state => productId => {
      let quantity = 0;

      state.items.forEach(cartItem => {
        if (cartItem._id === productId) {
          quantity += cartItem.quantity;
        }
      });

      return quantity;
    },
    cartItem: state => hashId => state.items.find(cartItem => cartItem.hashId === hashId),
    cartItems: state => state.items.map(item => reduceCartItem(item)),
    cartTotalPrice: state =>
      state.items
        .map(item => reduceCartItem(item))
        .reduce((previousValue, currentItem) => previousValue + currentItem.totalPrice, 0),
    productCount: state =>
      state.items.reduce((previousValue, currentItem) => previousValue + currentItem.quantity, 0),
    paymentMethodCode: state => state.paymentMethod,
    scheduleOrder: state => state.scheduleOrder,
    selectedScheduleOrderLabel: state => {
      let label = 'Giao hàng ngay 15 - 20 phút';

      if (state.scheduleOrder) {
        const dateFormatted = state.scheduleOrder.startDateTime.locale('vi').calendar(null, {
          sameDay: '[Hôm nay]',
          nextDay: '[Ngày mai]',
          nextWeek: 'dd',
          lastDay: '[Hôm qua]',
          lastWeek: 'dddd tuần trước',
          sameElse: 'DD/MM',
        });
        const startTimeFormatted = state.scheduleOrder.startDateTime.format('H:mm');
        const endTimeFormatted = state.scheduleOrder.endDateTime.format('H:mm');

        label = `${dateFormatted}, ${startTimeFormatted} - ${endTimeFormatted}`;
      }

      return label;
    },
  },
};
