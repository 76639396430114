const wrapperDecorations = (createElementFn, content, props) => {
  let currentContent = content;

  function wrap(needed, tag) {
    if (!needed) return;

    currentContent = createElementFn(tag, [currentContent]);
  }

  wrap(props.strong, 'strong');
  wrap(props.underline, 'u');
  wrap(props.del, 'del');
  wrap(props.code, 'code');
  wrap(props.mark, 'mark');
  wrap(props.keyboard, 'kbd');
  wrap(props.italic, 'i');

  return currentContent;
};

export default wrapperDecorations;
