import { camelCase } from '@/plugins/lodash';

const asyncSliceCreator = (name, asyncFetcher, options = {}) => {
  const { initialData, transformResponse = res => res.data } = options;

  const types = {
    REQUEST: `${name}/request`,
    SUCCESS: `${name}/success`,
    FAILURE: `${name}/failure`,
    RESET: `${name}/reset`,
  };

  const slice = {
    state: () => ({
      [name]: {
        isFetching: false,
        data: initialData ?? null,
        error: null,
      },
    }),
    mutations: {
      [types.REQUEST](state) {
        state[name].isFetching = true;
      },
      [types.SUCCESS](state, data) {
        state[name].isFetching = false;
        state[name].data = data;
        state[name].error = null;
      },
      [types.FAILURE](state, error) {
        state[name].isFetching = false;
        state[name].error = error;
      },
      [types.RESET](state) {
        state[name].isFetching = false;
        state[name].data = initialData ?? null;
        state[name].error = null;
      },
    },
    actions: {
      async [camelCase(`get-${name}`)](context, params) {
        const { commit } = context;

        try {
          commit(types.REQUEST);

          const response = await asyncFetcher(params, context);

          const transformedResponse = transformResponse(response);

          commit(types.SUCCESS, transformedResponse);

          return transformedResponse;
        } catch (error) {
          commit(types.FAILURE, {
            message: error?.message,
            stack: error?.stack,
            name: error?.name,
            code: error?.code,
          });

          throw error;
        }
      },
      [camelCase(`reset-${name}`)]({ commit }) {
        commit(types.RESET);
      },
    },
    getters: {
      [camelCase(`is-${name}-fetching`)]: state => state[name].isFetching,
      [camelCase(`is-${name}-loading`)]: state => state[name].isFetching && !state[name].data,
      [camelCase(`is-${name}-error`)]: state => Boolean(state[name].error),
      [camelCase(`${name}-error`)]: state => state[name].error,
      [camelCase(`${name}`)]: state => state[name].data,
    },
  };

  return [slice, { types, name }];
};

export default asyncSliceCreator;
