//
//
//
//
//
//

import { Row as AntRow } from 'ant-design-vue';

export default {
  components: {
    AntRow,
  },
};
