// import jwtDecode from 'jwt-decode'
// import dsBridge from 'dsbridge'
// import postRobot from 'post-robot'
import qs from 'qs';
import { getCookie, getToken, setCookie, storeToken } from '@/plugins/utils';
import { webInApp_getToken } from '@/plugins/webInAppMethods';
import { COOKIE_EXPIRES_MAX_AGE__3M, ERROR_CODES, ENABLE_JWT_REFRESH_TOKEN } from '@/mixins/constances';

const checkoutTokenNotFound = response => {
  if (
    response &&
    ((response.data && response.data.code === ERROR_CODES.TOKEN_NOT_FOUND.code) ||
      (response.status === 404 && response.data.title === 'Token not found'))
  ) {
    return true;
  }

  return false;
};

const checkoutTokenExpired = response => {
  if (response && response.data && response.data.code === ERROR_CODES.NOT_AUTHORIZED.code) {
    return true;
  }

  return false;
};

export const installAxiosInterceptors = (axios, store) => {
  // Interceptor request
  axios.interceptors.request.use(
    async config => {
      config.rawConfig = JSON.parse(JSON.stringify(config));

      if (config.token_required) {
        const [token] = await getJWTToken(ENABLE_JWT_REFRESH_TOKEN, store);

        if (!token) {
          // Show dialog require authenticate
          store.dispatch('setRequiredAuthModal', true);

          // Cancel request if cannot get token
          return {
            ...config,
            cancelToken: new axios.CancelToken(cancel => cancel('USER_NOT_LOGIN_IN')),
          };
        }

        return updateAxiosConfig(config, token);
      }
      const newConfig = updateAxiosConfig(config);

      return newConfig;
    },
    error => Promise.reject(error),
  );

  // Interceptor response
  axios.interceptors.response.use(
    res => res,

    async error => {
      /* refresh token and retry request once more on NOT_AUTHORIZED
        else return error
     */
      const { config: originalRequest, response } = error;

      if (checkoutTokenNotFound(response)) {
        store.dispatch('setRequiredAuthModal', true);
      }

      // skip refresh token request, add retry attempts to avoid infinite loops
      if (
        ENABLE_JWT_REFRESH_TOKEN &&
        originalRequest &&
        !originalRequest.url.includes(endpoints.refresh_token) &&
        !originalRequest.isRetryAttempt &&
        checkoutTokenExpired(response)
      ) {
        try {
          const token = await refreshToken();
          if (token) {
            const modifiedOriginalRequest = updateAxiosConfig(originalRequest.rawConfig, token);
            // add flag to prevent infinite loops
            modifiedOriginalRequest.isRetryAttempt = true;

            return await axios.request({
              ...modifiedOriginalRequest,
              ...originalRequest,
            });
          }
        } catch (e) {
          console.error(e);
          // log user out if persistent NOT_AUTHORIZED errors from original requests
          if (checkoutTokenExpired(e.response)) {
            // store.dispatch(push('/login'))

            return Promise.reject(e);
          }
        }
      }

      // Normal flow: return error to caller
      return Promise.reject(error);
    },
  );
};

export function updateAxiosConfig(config = {}, token) {
  if (token) {
    const method = config.method.toLowerCase();

    // add token to header authorization
    config.headers = config.headers || {};
    config.headers['Authorization'] = `Bearer ${token}`;

    // add token
    if (config.have_token_in_params) {
      switch (method) {
        case 'get':
        case 'delete': {
          const params = config.params || {};

          params['token'] = token;
          config.params = params;

          break;
        }
        case 'post':
        case 'put': {
          const data = config.data || {};

          data['token'] = token;
          config.data = data;

          break;
        }
        default:
      }
    }
  }

  if (config.stringify_data && config.data) {
    config.data = qs.stringify(config.data);
  }

  // console.log("new config", config);
  return config;
}

export const getJWTToken = async (checkExpiredToken, store) => {
  let token = getCookie('token') || getToken() || '';
  let isNewToken = false;

  if (token && checkExpiredToken) {
    try {
      token = await refreshToken(store);
      if (token) isNewToken = true;
    } catch (e) {
      console.error(e);
    }
  }

  return [token, isNewToken];
};

let refreshTokenPromise;

export const refreshToken = async store => {
  let token = '';

  // TODO: use webInAppConfig to handle communicate with other side
  // use private variable to keep 1 active JWT refresh request at any time.
  refreshTokenPromise = refreshTokenPromise || webInApp_getToken();

  // get new token
  try {
    if (!refreshTokenPromise) {
      console.error('NO_HANDLER_TO_REFRESH_TOKEN');
      return token;
    }

    const payload = await refreshTokenPromise;

    if (payload && payload.token) {
      token = payload.token;
      storeToken(token);
      setCookie('token', payload.token, {
        exdays: COOKIE_EXPIRES_MAX_AGE__3M,
        domain: process.env.NUXT_ENV_APP_DOMAIN,
      });
      store.dispatch('setToken', payload.token);
    }
  } catch (error) {
    console.error(error);
  } finally {
    refreshTokenPromise = null;
  }

  return token;
};
