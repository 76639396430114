import { asyncSliceCreator } from '@/utils';
import * as dealAPI from '@/api/deal';
import { entries } from 'lodash';

const [dealItemGroupStore] = asyncSliceCreator('dealItemStores', dealAPI.listDeal);
const [dealDetail] = asyncSliceCreator('dealItemStoresDetail', dealAPI.dealDetail);

export const types = {
  SET_DEAL_ITEM_ACTIVE: 'deal/set_active',

  LIST_DEAL_REQUEST_MORE: 'deal/list_deal_request_more',
  LIST_DEAL_SUCCESS_MORE: 'deal/list_deal_success_more',
  LIST_DEAL_FAILURE_MORE: 'deal/list_deal_failure_more',

  SOLD_DEALS: 'deal/sold_deals',
  CLEAR_DEAL: 'deal/clear_deal',
};

export default {
  state: () => ({
    ...dealItemGroupStore.state(),
    ...dealDetail.state(),
    dealActive: null,
    listDeal: [],
    sold: 0,
    error: null,
    isLoading: false,
  }),
  mutations: {
    ...dealItemGroupStore.mutations,
    ...dealDetail.mutations,
    [types.SET_DEAL_ITEM_ACTIVE](state, deal) {
      state.dealActive = deal;
    },
    [types.LIST_DEAL_SUCCESS_MORE](state, deals) {
      state.listDeal = [...state.listDeal, ...deals];
      state.isLoading = false;
    },
    [types.LIST_DEAL_REQUEST_MORE](state) {
      state.isLoading = true;
    },
    [types.LIST_DEAL_FAILURE_MORE](state, err) {
      state.error = err.message;
      state.isLoading = false;
    },
    [types.SOLD_DEALS](state, sold) {
      state.sold = sold;
    },
    [types.CLEAR_DEAL](state) {
      state.listDeal = [];
    },
  },
  actions: {
    ...dealItemGroupStore.actions,
    ...dealDetail.actions,
    setDealActive({ commit }, item) {
      commit(types.SET_DEAL_ITEM_ACTIVE, item);
    },
    async getMoreListDeal(context, params) {
      const { commit } = context;

      try {
        commit(types.LIST_DEAL_REQUEST_MORE);

        const response = await dealAPI.listDeal(params, context);

        commit(types.LIST_DEAL_SUCCESS_MORE, response.data.deals);
        commit(types.SOLD_DEALS, response.data.sold);

        return response.data;
      } catch (error) {
        commit(types.LIST_DEAL_FAILURE_MORE, {
          message: error?.message,
          stack: error?.stack,
          name: error?.name,
          code: error?.code,
        });

        throw error;
      }
    },
    getClearDeal({ commit }) {
      commit(types.CLEAR_DEAL);
    },
  },
  getters: {
    ...dealItemGroupStore.getters,
    ...dealDetail.getters,
    dealActive: state => state.dealActive,
    listDeal: state => state.listDeal,
    soldDeal: state => state.sold,
  },
};
