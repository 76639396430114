//
//
//
//
//
//
//
//
//
//
//

import { postMessage } from '@@/plugins/postMessage';
import Close from '~/assets/svg/header/Close.svg?inline';
import Home from '~/assets/svg/header/Home.svg?inline';
import Back from '~/assets/svg/header/Back-iOS.svg?inline';

export default {
  name: 'HeaderMobile',
  components: {
    Close,
    Home,
    Back,
  },
  scrollToTop: true,
  data() {
    return {
      isSwitch: false,
      limitPosition: 500,
      lastPosition: 0,
    };
  },
  methods: {
    onBack() {
      this.$router.go(-1);
      postMessage('back');
    },
    onClose() {
      postMessage('close');
      console.log('🚀 ~ file: header.vue ~ line 37 ~ onClose ~ onClose');
    },
    onHome() {
      this.$router.push(this.withPartnerPath('/'));
    },
  },
};
