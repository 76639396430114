var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"to":"responsive-modal-portal"}},[(_vm.rendered)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"absolute inset-0 outline-none"},[_c('div',{staticClass:"absolute top-0 left-0 h-full w-full bg-gray-500 opacity-75 z-50",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.$emit('close')}}}),_vm._v(" "),_c('div',{staticClass:"relative h-full w-full overflow-auto"},[_c('div',{class:{
          'absolute md:relative bottom-0 left-0 z-50 w-full md:h-auto md:max-w-lg md:mx-auto md:mt-12 md:mb-12': true,
          'h-full': _vm.full,
        }},[_c('div',{class:{
            'z-50 h-full bg-white md:rounded-t-lg md:rounded-b-lg shadow-xl transform transition-all overflow-x-hidden': true,
            'rounded-t-lg': !_vm.full,
            'overflow-y-auto': _vm.full,
          },attrs:{"tabindex":"0","role":"dialog","aria-modal":"true","aria-labelledby":"modal-headline"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('close')}}},[_vm._t("default")],2)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }