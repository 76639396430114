import { get } from '~/api/API';
import { API } from '~/mixins/constances';

export const dealDetail = param => {
  const uri = API.deal.deal_detail + '/' + param.slug;

  return get(uri, param, {
    token_required: false,
  });
};

export const listDeal = (params = {}, config = {}) => {
  const uri = API.deal.list_deals;
  return get(uri, params, {
    token_required: false,
    ...config,
  });
};
