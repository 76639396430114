//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Facebook from '~/assets/svg/footer/Facebook.svg?inline';
import Instagram from '~/assets/svg/footer/Instagram.svg?inline';
import Youtube from '~/assets/svg/footer/Youtube.svg?inline';

export default {
  components: {
    Facebook,
    Instagram,
    Youtube,
  },
};
