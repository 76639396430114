import { post, get, put } from '~/api/API';
import { API } from '~/mixins/constances';

export const createOrder = params => {
  const uri = API.order.create;
  return post(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
    },
    have_token_in_params: true,
    stringify_data: true,
  });
};

export const createOrderV3 = params => {
  const uri = API.order.create_v3;
  return post(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    have_token_in_params: false,
  });
};

export const estimateFee = (params, config) => {
  const uri = API.order.estimated_fee;
  return post(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    have_token_in_params: true,
    ...config,
  });
};

export const estimateFeeV3 = (params, config) => {
  const uri = API.order.estimated_fee_v3;
  console.log(params, config);
  return post(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    have_token_in_params: false,
    ...config,
  });
};

export const userProfile = params => {
  const uri = API.user.profile;
  return get(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
    have_token_in_params: true,
  });
};

export const getPromoList = params => {
  const uri = API.order.get_promo_codes;
  return get(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
    have_token_in_params: true,
  });
};

export const getPromoDetail = params => {
  const uri = API.order.get_promo_code + params.code;
  delete params.code;

  return get(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
    have_token_in_params: true,
  });
};

export const getOrderPaymentMethods = params => {
  const uri = API.order.payment_methods;

  return get(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
    have_token_in_params: true,
  });
};

export const getServiceDetail = params => {
  const uri = API.order.service_detail;

  return get(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
    have_token_in_params: true,
  });
};

export const getOrderDetail = params => {
  const uri = API.order.order_detail + params.orderId;

  return get(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
    have_token_in_params: true,
  });
};

export const getLatestOrderByToken = params => {
  const uri = API.order.latest_order;

  return get(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
  });
};

export const confirmOrder = params => {
  const uri = API.order.confirm_update;

  return put(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
  });
};

export const cancelOrder = params => {
  const uri = API.order.cancel;

  return get(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
  });
};
