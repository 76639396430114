export const types = {
  HOME_PAGE_SCROLL_TOP: 'ux/homePageScrollTop',
};

export default {
  state: () => ({
    homePageScrollTop: 0,
  }),
  mutations: {
    [types.HOME_PAGE_SCROLL_TOP](state, top) {
      state.homePageScrollTop = top;
    },
  },
  actions: {
    updateHomePageScrollTop({ commit }, top) {
      commit(types.HOME_PAGE_SCROLL_TOP, top);
    },
    resetHomePageScrollTop({ commit }) {
      commit(types.HOME_PAGE_SCROLL_TOP, 0);
    },
  },
  getters: {
    homePageScrollTop: state => state.homePageScrollTop,
  },
};
