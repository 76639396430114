import { get, post } from '~/api/API';
import { API } from '~/mixins/constances';

export const placeAutocomplete = params => {
  const uri = API.rest_api.place_autocomplete;

  return get(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
  });
};

export const placeDetails = params => {
  const uri = API.rest_api.place_details;

  return get(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
  });
};

export const placeGeocodeReverse = params => {
  const uri = API.rest_api.place_geocode_reverse;

  return post(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
  });
};

export const publicPlaceAutocomplete = params => {
  const uri = API.rest_api.public_place_autocomplete;

  return get(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
    token_required: false,
  });
};

export const publicPlaceDetails = params => {
  const uri = API.rest_api.public_place_details;

  return get(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
    token_required: false,
  });
};

export const publicPlaceGeocodeReverse = params => {
  const uri = API.rest_api.public_place_geocode_reverse;

  return get(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
    token_required: false,
  });
};
