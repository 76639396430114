//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Facebook from '~/assets/svg/footer/Facebook.svg?inline';
import Instagram from '~/assets/svg/footer/Instagram.svg?inline';
import Youtube from '~/assets/svg/footer/Youtube.svg?inline';
import LocationIcon from '~/assets/svg/footer/Location.svg?inline';
import MailIcon from '~/assets/svg/footer/MailIcon.svg?inline';
import PhoneIcon from '~/assets/svg/footer/PhoneIcon.svg?inline';

export default {
  name: 'FooterDesktop',
  components: {
    Facebook,
    Instagram,
    MailIcon,
    PhoneIcon,
    Youtube,
    LocationIcon,
  },
};
