import { post } from '~/api/API';
import { API } from '~/mixins/constances';

export const momoActivate = params =>
  post(API.auth.momo_activate, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
    stringify_data: false,
    token_required: false,
  });

export const viettelPayActivate = params =>
  post(API.auth.viettelpay_activate, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
    stringify_data: false,
    token_required: false,
  });
