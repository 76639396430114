import { axiosInstance } from '@/plugins/axios';

const defaultConfig = {
  token_required: true,
  stringify_data: false,
};

/*
 * Wraps axios and provides
 * more convenient POST method
 * calls with data
 */
export function post(uri, data = {}, config = {}) {
  config = {
    ...defaultConfig,
    ...config,
  };

  return axiosInstance.post(uri, data, config);
}

/*
 * Wraps axios and provides
 * more convenient GET method
 * calls with data.
 */
export function get(uri, params = {}, config = {}) {
  config = {
    params,
    ...defaultConfig,
    ...config,
  };

  return axiosInstance.get(uri, config);
}

/*
 * Wraps axios and provides
 * more convenient PUT method
 * calls with data.
 */

export function put(uri, data = {}, config = {}) {
  config = {
    ...defaultConfig,
    ...config,
  };

  return axiosInstance.put(uri, data, config);
}

/*
 * Wraps axios and provides
 * more convenient DELETE method
 * calls with data.
 */

export function remove(uri, params = {}, config = {}) {
  config = {
    ...defaultConfig,
    ...config,
    params,
  };

  return axiosInstance.delete(uri, config);
}
