export const order = {
  countries: '/v1/order/countries',
  service_types: '/v1/order/service_types',
  detail: '/v1/order/detail',
  list: '/v1/order/list',
  locate_supplier: '/v1/order/locate_supplier',
  rate_supplier: '/v1/order/rate_supplier',
  create_ticket: '/v1/order/create_ticket',
};

export const user = {
  news: '/v1/user/news',
  login: '/v1/user/login',
  social_login: '/v1/user/social_login',
  activate: '/v1/user/activate',
  register: '/v1/user/register',
  logout: '/v1/user/logout',
  profile: '/v1/user/profile',
  update_push: '/v1/user/update_push',
  update_language: '/v1/user/update_language',
  refresh_firebase_token: '/v1/user/refresh_firebase_token',
  get_push_data: '/v1/user/get_push_data',
};

export const supplier = {
  file: '/v1/supplier/file',
};

export const store = {
  nearby_stores: '/v2/store/nearby_stores',
  campaign_list: '/v2/store/campaign_list',
  detail: '/v2/store/detail',
  cancel_order: '/v2/store/cancel_order',
  file: '/v2/store/file',
  estimated_fee: '/v2/store/estimated_fee',
  create_order: '/v2/store/create_order',
  rate_store: '/v2/store/rate_store',
  pay_momo_web_order: '/v2/store/pay_momo_web_order',
  recommended_stores: '/v2/store/recommended_stores',
  search_suggest: '/v2/store/search_suggest',
};
