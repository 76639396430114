import { get, post, put } from '~/api/API';
import { API } from '~/mixins/constances';

export const groupDetail = param => {
  const uri = API.group.group_detail + param.id;
  let baseURL = process.env.NUXT_ENV_API_BE_URL;

  return get(uri, param, {
    baseURL,
    token_required: true,
  });
};

export const getListGroups = (params, config = {}) => {
  const uri = `${API.group.list_groups}${params.id}`;
  let baseURL = process.env.NUXT_ENV_API_BE_URL;

  return get(
    uri,
    {},
    {
      baseURL,
      token_required: true,
      ...config,
    },
  );
};

export const createGroup = (params, config = {}) => {
  const uri = API.group.create_group;
  let baseURL = process.env.NUXT_ENV_API_BE_URL;

  return post(uri, params, {
    baseURL,
    token_required: true,
    ...config,
  });
};

export const leaveGroup = (params = {}, config = {}) => {
  const uri = API.group.leave_group + params.id;
  let baseURL = process.env.NUXT_ENV_API_BE_URL;
  return put(uri, params, {
    baseURL,
    token_required: true,
    ...config,
  });
};
