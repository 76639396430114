import { WEB_IN_APP_CONFIG } from '@/mixins/constances';

const getWebInAppConfig = webInAppId => WEB_IN_APP_CONFIG.find(({ id }) => id === webInAppId);

export default function (context) {
  const { route, query, redirect } = context;
  const { fullPath, path } = route;

  // Redirect old path to new
  let fullPathConverted = fullPath;
  if (fullPathConverted.includes('/tag/')) {
    fullPathConverted = fullPathConverted.replace('/tag/', '/tags/');
    return redirect(fullPathConverted);
  } else if (fullPathConverted.includes('/brand/')) {
    fullPathConverted = fullPathConverted.replace('/brand/', '/brands/');
    return redirect(fullPathConverted);
  } else if ((fullPathConverted.includes('/store?') || fullPathConverted.includes('/store/?')) && query.id) {
    fullPathConverted = fullPathConverted.replace('/store', `/stores/${query.id}`);
    return redirect(fullPathConverted);
  } else if (fullPathConverted.includes('/store/')) {
    fullPathConverted = fullPathConverted.replace('/store/', '/stores/');
    return redirect(fullPathConverted);
  } else if ((fullPathConverted.includes('/deal?') || fullPathConverted.includes('/deal/?')) && query.id) {
    fullPathConverted = fullPathConverted.replace('/deal', `/deal/${query.id}`);
    return redirect(fullPathConverted);
  } else if (fullPathConverted.includes('/deal/')) {
    fullPathConverted = fullPathConverted.replace('/deal/', '/deals/');
    return redirect(fullPathConverted);
  } else if (fullPathConverted.includes('/group/')) {
    fullPathConverted = fullPathConverted.replace('/group/', '/groups/');
    return redirect(fullPathConverted);
  } else if (fullPathConverted.includes('/order-group/')) {
    fullPathConverted = fullPathConverted.replace('/order-group/', '/order-groups/');
    return redirect(fullPathConverted);
  }

  // Redirect /?webinapp=[web_in_app_id] => /[web_in_app_id]/
  const configFromQuery = getWebInAppConfig(query.webinapp);
  const configFromPath = getWebInAppConfig(path.split('/')[1]);
  if (configFromQuery && !configFromPath) {
    return redirect(`/${configFromQuery.id}${fullPath}`);
  }
}
