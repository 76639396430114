import {
  WEB_IN_APP_MOMO_ID,
  WEB_IN_APP_VIETTELPAY_ID,
  WEB_IN_APP_ZALO_ID,
  WEB_IN_APP_ZALOPAY_ID,
} from '@/mixins/constances';

export const locale = state => state.locale;
export const backPath = state => {
  return state.backPath ? state.backPath : `/${state.locale}/`;
};
export const appStatus = state => state.appStatus;
export const firebaseRemoteConfig = state => state.firebaseRemoteConfig;
export const scrollPosition = state => state.scrollPosition;
export const localeSuffix = state => (state.locale === 'vi' ? '_vi_vn' : '_en_us');
export const userProfile = state => state.userProfile;
export const webInAppConfig = state => state.webInAppConfig;
export const shouldShowBackActions = state =>
  ![WEB_IN_APP_MOMO_ID, WEB_IN_APP_VIETTELPAY_ID, WEB_IN_APP_ZALO_ID, WEB_IN_APP_ZALOPAY_ID].includes(
    state?.webInAppConfig?.id,
  );
