export const webInApp_methods = {
  GET_TOKEN: 'getToken',
  OPEN_PAGE: 'openPage',
  CLOSE_WEB_IN_APP: 'close',
};

// dsBridge methods

const dsBridge_getToken = () => {
  new Promise((resolve, reject) => {
    if (!dsBridge || !dsBridge.hasNativeMethod(webInApp_methods.GET_TOKEN)) return reject();

    dsBridge.call(webInApp_methods.GET_TOKEN, token => {
      if (token) {
        resolve({ token });
      } else reject();
    });
  });
};

// webinapp methods

export const webInApp_hasNativeMethod = name => {
  // TODO: check method of mobile or web by webInAppConfig
  return (dsBridge && dsBridge.hasNativeMethod(name)) || false;
};

export const webInApp_getToken = () => {
  // TODO: check app type: mobile or web by webInAppConfig
  return dsBridge_getToken();
};

export const webInApp_goToOrderDetail = id => {
  dsBridge.call(webInApp_methods.OPEN_PAGE, `ahamove://order_detail?pay_now=true&order_id=${id}`);
};

export const webInApp_closeWebInApp = () => {
  dsBridge.call(webInApp_methods.CLOSE_WEB_IN_APP);
};
