import { storeDeliverAddress, isValidLocation } from '@/utils';
import { publicPlaceGeocodeReverse } from '@/api/address';

export default async ({ query, store, $sentry }) => {
  const { dispatch } = store;
  const { lat, lng } = query;

  const isValid = isValidLocation({ lat: Number(lat), lng: Number(lng) });

  if (isValid) {
    storeDeliverAddress({ lat: Number(lat), lng: Number(lng) });

    publicPlaceGeocodeReverse({
      lat,
      lng,
    })
      .then(response => {
        for (let index = 0; index < response.data?.data?.features.length; index++) {
          const { properties, geometry } = response.data?.data?.features[index];

          if (Boolean(properties.address || properties.name)) {
            const geoCodeReversed = {
              coordinate: {
                lng: geometry.coordinates[0],
                lat: geometry.coordinates[1],
              },
              address: properties.address || properties.name,
            };

            dispatch('v2/updateAddress', geoCodeReversed);

            break;
          }
        }
      })
      .catch(error => {
        $sentry.captureException(error);
      });
  }

  dispatch('v2/initCart');
  dispatch('v2/intitDeliveryInfo');
};
