export const COOKIE_NAME__LOCATION = 'location';
export const COOKIE_NAME__VOTE = 'vote';
export const COOKIE_NAME__TOKEN = 'token';
export const COOKIE_NAME__LANGUAGE = 'language';
export const COOKIE_EXPIRES_MAX_AGE__3M = 90;
export const AFFILIATE_ID = 'affiliate_id';
export const COOKIE_EXPIRES_MAX_AGE__1D = 1;
export const COOKIE_AFF_NETWORK = '_aff_network';
export const COOKIE_REQUEST_ID = 'request_id';
export const COOKIE_MOMO_NEWUSER = 'momo_newuser';

export const COOKIE_NAME__BREADCRUMBS = 'breadcrumbs';

export const LOCAL_STORAGE_KEY__USER = 'user';
export const LOCAL_STORAGE_KEY__PAYMENT_TYPE = 'payment_type';
export const LOCAL_STORAGE_KEY__MESSAGES = 'messages';
export const LOCAL_STORAGE_KEY__DELIVERY_NOTE = 'delivery_note';
export const LOCAL_STORAGE_KEY__LOCATIONS = 'locations';
export const LOCAL_STORAGE_KEY__RECENT_STORES = 'recent_stores';
export const LOCAL_STORAGE_KEY__LOCATIONS_MAX = 3;

export const RECOMMEND = {
  MOBILE_INIT_NUM: 7,
  INIT_NUM: 9,
};

export const STORE_PARENT_VIEWS = {
  RECOMMEND: 'recommend',
  NEAR_STORES: 'near_store',
  CAMPAIGN: 'campaign',
};

export const STORES_GROUP_OPTIONS = {
  ALL: 'all',
  RECOMMEND_STORES: 'recommend_stores',
  UNDER_30_MINS: 'under_30_mins',
  TRY_SOMETHING_NEW: 'try_something_new',
  DRINKS: 'drinks',
  MORE: 'more',
  FILTER_BY_CATEGORY: 'filter_by_category',
};

export const STORE_CATEGORIES = [
  {
    _id: 'popular_brand',
    key: 'Popular Brand',
  },
  {
    _id: 'offer_around',
    key: 'Offers Near You',
  },
  {
    _id: 'whats_new',
    key: "What's New",
  },
  {
    _id: 'editors_choice',
    key: "Editor's Choice",
  },
  {
    _id: 'healthy_vegetarian',
    key: 'Healthy - Vegan',
  },
  {
    _id: 'lunch',
    key: 'Lunch',
  },
  {
    _id: 'free_delivery',
    key: 'Free Delivery',
  },
];

export const STORES_FILTER_UNDER_MINUTES = 35;
export const SERVICE__FOOD = 'FOOD';

export const ORDER_STATUS = {
  PAYING: 'PAYING',
  CONFIRMING: 'CONFIRMING',
  ASSIGNING: 'ASSIGNING',
  ACCEPTED: 'ACCEPTED',
  IN_PROCESS: 'IN PROCESS',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};

export const MESSAGE_GROUP = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  OTHERS: 'others',
};

export const DEFAULT_COUNTRY_LIST = [
  {
    _id: 'VN',
    name: 'Vietnam',
    currency: 'VND',
    calling_code: 84,
  },
];

export const DEFAULT_COUNTRY_ID = 'VN';

export const CITY_LOCATION_BY_DEFAULT = {
  SGN: {
    friendly_url: 'ho-chi-minh',
    latitude: 10.777171,
    longitude: 106.695503,
  },
  HAN: {
    friendly_url: 'ha-noi',
    latitude: 21.028667,
    longitude: 105.852148,
  },
};

export const FOOD_SERVICE_NAME_BY_DEFAULT = 'giao-do-an';

export const LOCATE_LANGUAGE = {
  VI: {
    locate: 'vi',
    language: 'vi_VN',
  },
  EN: {
    locate: 'en',
    language: 'en_US',
  },
};
export const DEFAULT_LOCATE = 'vi';

export const CURRENCY_SYMBOL = {
  VND: '₫',
  USD: '$',
};

export const ONLINE_PAYMENT_METHODS = ['MOMO', 'ZALOPAY'];
export const PAYMENT_TYPE = {
  COD: 'cod',
  MOMO: 'momo',
  ZALOPAY: 'zalopay',
};

export const AFFILIATE_IDS = {
  MOMO: 'momo',
  ZALO: 'zalo',
  ECOMOBI: 'ecomobi',
};

export const AFFILIATE_CONFIG = {
  MOMO: {
    COOKIE_MAX_AGE: '1D',
  },
  SHOPINESS: {
    COOKIE_MAX_AGE: '1D',
  },
};

export const CHAT = {
  MESSAGE_STATUS: {
    SENT: 1,
    READ: 2,
  },
};

export const IGNORED_QUERY_FIELDS = [
  'mobile',
  'name',
  'email',
  'keyword',
  'facebook_id',
  'google_id',
  'lat',
  'lng',
];

export const MIN_NUM_RATING = 10;

export const HTTP_REQUEST = {
  TIMEOUT: 5000,
  HEADERS: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  },
};

export const API = {
  auth: {
    momo_activate: '/api/v3/public/user/momo_activate',
    viettelpay_activate: '/api/v3/public/user/vtp_activate',
  },
  order: {
    countries: '/v1/order/countries',
    service_types: '/v1/order/service_types',
    detail: '/v1/order/detail',
    list: '/v1/order/list',
    locate_supplier: '/v1/order/locate_supplier',
    rate_supplier: '/v1/order/rate_supplier',
    create_ticket: '/v1/order/create_ticket',
    create: '/v1/order/create',
    create_v3: '/api/v3/private/order/create',
    estimated_fee: '/v2/order/estimated_fee',
    estimated_fee_v3: '/api/v3/private/order/estimate',
    get_promo_codes: '/v1/user/get_promo_codes',
    get_promo_code: '/api/v3/private/promotion/',
    payment_methods: '/api/v3/private/payment/methods/order?payment_type=order',
    service_detail: '/v1/order/service_detail',
    order_detail: '/api/v3/private/order/detail/',
    latest_order: '/v2/order/latest_order',
    confirm_update: '/api/v3/private/order/mart/items/confirm-update',
    cancel: '/v1/order/cancel',
  },
  user: {
    news: '/v1/user/news',
    login: '/v1/user/login',
    social_login: '/v1/user/social_login',
    activate: '/v1/user/activate',
    register: '/v1/user/register',
    logout: '/v1/user/logout',
    update_push: '/v1/user/update_push',
    update_language: '/v1/user/update_language',
    refresh_firebase_token: '/v1/user/refresh_firebase_token',
    get_push_data: '/v1/user/get_push_data',
    profile: '/v1/user/profile',
    update_profile: '/v1/user/update_profile',
  },
  supplier: {
    file: '/v1/supplier/file',
  },
  store: {
    search: '/v2/store/search',
    search_new: '/v2/store/new_search',
    nearby_stores: '/v2/store/nearby_stores',
    tag: '/v2/tag/all',
    brand: '/v2/store/chain',
    campaign_list: '/v2/store/campaign_list',
    detail: '/v2/store/detail',
    cancel_order: '/v2/store/cancel_order',
    file: '/v2/store/file',
    estimated_fee: '/v2/store/estimated_fee',
    create_order: '/v2/store/create_order',
    rate_store: '/v2/store/rate_store',
    pay_momo_web_order: '/v2/store/pay_momo_web_order',
    pay_zalo_web_order: '/v2/store/pay_zalo',
  },
  payment: {
    description: '/lala_payment_types',
  },
  rest_api: {
    place_autocomplete: '/web/v3/place/autocomplete',
    place_details: '/web/v3/place/details',
    place_geocode_reverse: '/web/v3/place/geocode_reverse',
    public_place_autocomplete: '/web/v3/public/place/autocomplete',
    public_place_details: '/web/v3/public/place/details',
    public_place_geocode_reverse: '/web/v3/public/place/geocode_reverse',
  },
  master_data: {
    screen_configs: '/api/v3/public/master-data/screen-configs',
  },
  group: {
    create_group: '/api/v3/private/order/mart/group/create',
    list_groups: '/api/v3/private/order/mart/group/list/',
    group_detail: '/api/v3/private/order/mart/group/',
    leave_group: '/api/v3/private/order/mart/group/leave/',
  },
  deal: {
    list_deals: '/v2/deal/list',
    deal_detail: '/v2/deal/detail',
  },
};

export const WEB_IN_APP_COMMUNICATION = {
  DSBRIDGE: 'dsbridge',
  POST_ROBOT: 'post-robot',
};

export const PAYMENT_METHODS = {
  CASH: 'CASH',
  CASH_BY_RECIPIENT: 'CASH_BY_RECIPIENT',
  BALANCE: 'BALANCE',
  MOMO: 'MOMO',
  ZALOPAY: 'ZALOPAY',
  SMARTPAY: 'SMARTPAY',
  VIETTELPAY: 'VIETTELPAY',
};

export const SUPPORTED_PAYMENT_METHODS = [
  PAYMENT_METHODS.CASH_BY_RECIPIENT,
  // PAYMENT_METHODS.CASH_BY_RECIPIENT,
  PAYMENT_METHODS.BALANCE,
  PAYMENT_METHODS.MOMO,
  PAYMENT_METHODS.ZALOPAY,
  PAYMENT_METHODS.SMARTPAY,
  PAYMENT_METHODS.VIETTELPAY,
];

export const PLATFORMS = {
  WEB: 'WEB',
  WAP: 'WAP',
  MOMO: 'MOMO',
  ZALOPAY: 'ZALOPAY',
  ZALO: 'ZALO',
  SMARTPAY: 'SMARTPAY',
  VIETTELPAY: 'VIETTELPAY',
};

export const WEB_IN_APP_AHAMOVE_WEB_ID = 'w_ahamove';
export const WEB_IN_APP_AHAMOVE_MOBILE_ID = 'ahamove';
export const WEB_IN_APP_MOMO_ID = 'momo_deeplink';
export const WEB_IN_APP_ZALO_ID = 'zalo';
export const WEB_IN_APP_ZALOPAY_ID = 'zalopay';
export const WEB_IN_APP_SMARTPAY_ID = 'smartpay_deeplink';
export const WEB_IN_APP_VIETTELPAY_ID = 'viettel-pay_deeplink';

/**
 * [WEB_IN_APP_CONFIG description]
 * communication = null: use default web handle
 * getTokenFunction === null: use default function ('communication' is required)
 * platform (required): used for remote configs
 */
export const WEB_IN_APP_CONFIG = [
  {
    id: WEB_IN_APP_AHAMOVE_WEB_ID,
    paymentMethods: null,
    platform: PLATFORMS.WAP,
    communication: WEB_IN_APP_COMMUNICATION.POST_ROBOT,
    getTokenFunction: 'getToken',
  },
  {
    id: WEB_IN_APP_AHAMOVE_MOBILE_ID,
    paymentMethods: null,
    platform: PLATFORMS.WAP,
    communication: WEB_IN_APP_COMMUNICATION.DSBRIDGE,
    getTokenFunction: 'getToken',
  },
  {
    id: WEB_IN_APP_MOMO_ID,
    paymentMethods: [PAYMENT_METHODS.MOMO],
    platform: PLATFORMS.MOMO,
    refId: process.env.NUXT_ENV_MOMO_REF_ID, // Used for MoMo redirect after payment success
    appId: process.env.NUXT_ENV_MOMO_APP_ID, // Used for MomoMiniAPI init GoShop miniapp
  },
  {
    id: WEB_IN_APP_ZALO_ID,
    paymentMethods: [PAYMENT_METHODS.ZALOPAY],
    platform: PLATFORMS.ZALO,
  },
  {
    id: WEB_IN_APP_ZALOPAY_ID,
    paymentMethods: [PAYMENT_METHODS.ZALOPAY],
    platform: PLATFORMS.ZALOPAY,
  },
  {
    id: WEB_IN_APP_VIETTELPAY_ID,
    paymentMethods: [PAYMENT_METHODS.VIETTELPAY],
    platform: PLATFORMS.VIETTELPAY,
    activate_type: 'web-goshop', // Used for activate viettelpay user (Auto login)
  },
  {
    id: WEB_IN_APP_SMARTPAY_ID,
    paymentMethods: [PAYMENT_METHODS.SMARTPAY],
    platform: PLATFORMS.SMARTPAY,
  },
];

export const USER_NOT_LOGIN_IN = 'USER_NOT_LOGIN_IN';

export const ERROR_CODES = {
  NOT_AUTHORIZED: {
    code: 'NOT_AUTHORIZED',
    http_code: 401,
    description: 'Not authorized, please check and try again.',
  },
  REFRESH_TOKEN_HAS_EXPIRED: {
    code: 'REFRESH_TOKEN_HAS_EXPIRED',
    http_code: 401,
    description: 'Your token has expired, please sign in again.',
  },
  REFRESH_TOKEN_NOT_FOUND: {
    code: 'REFRESH_TOKEN_NOT_FOUND',
    http_code: 404,
    description: 'Refresh token not found',
  },
  ITEM_NOT_VALID: { code: 'ITEM_NOT_VALID', http_code: 406 },
  TOTAL_PAY_NOT_VALID: {
    code: 'TOTAL_PAY_NOT_VALID',
    http_code: 402,
    description: 'Total pay not valid',
  },
  NOT_ENOUGH_CREDIT: {
    code: 'NOT_ENOUGH_CREDIT',
    http_code: 406,
    description: 'Not enough credit',
  },
  TOKEN_NOT_FOUND: {
    code: 'TOKEN_NOT_FOUND',
    http_code: 404,
    description: 'Token does not exist in system',
  },
};

export const JWT_REFRESH_BEFORE_EXPIRE = 30; // seconds
export const ENABLE_JWT_REFRESH_TOKEN = false;

export const CREATE_ORDER_ERROR_TEMPLATE = {
  COD_IS_NOT_ALLOWED: 'COD is not allowed for individual customer',
  NOT_ENOUGH_CREDIT: 'Not enough credit',
  BOOKING_TIME_INVALID: 'Booking time not valid',
  DISTANCE_NOT_VALID: 'Distance not valid',
  ITEM_NOT_VALID: 'Item not valid',
};

export const LOCAL_STORAGE_KEY__LATEST_PAYMENT_METHOD = 'latest_payment_method';
export const LOCAL_STORAGE_KEY__LATEST_PICKUP = 'latest_pickup';
export const LOCAL_STORAGE_KEY__LATEST_DROPOFF = 'latest_dropoff';
export const LOCAL_STORAGE_KEY__RECENT_KEYWORDS = 'recent_keywords';
export const LOCAL_STORAGE_KEY__CHECKOUT_CART_DETAIL = 'checkout_detail';

export const CURRENCY_CONFIG = {
  decimal: '.',
  thousands: ',',
  precision: 0,
  prefix: '₫',
};

export const CART_INPUT_ITEM_DEFAULT_ID = 'ASSISTANT_SERVICE_ITEM_ID';
export const STORE_INPUT_DEFAULT_ID = 'ASSISTANT_SERVICE_STORE_DEFAULT_ID';

export const SHOPPING_BANNER_TYPE = 'banner_goshop';
export const SHOPPING_TAG_TYPE_LIST = 'list';
export const SHOPPING_TAG_TYPE_SLIDES = 'slides';
export const SHOPPING_TAG_HOMESCREEN = 'home';
export const SHOPPING_ASSISTANT_SERVICE_MIN_ITEM_PRICE = 1000;
export const SHOPPING_ASSISTANT_SERVICE_MAX_ITEM_PRICE = 1000000;

export const DEFAULT_LOCATION = {
  lat: 21.046364,
  lng: 105.79525,
};

export const SHOPPING_ADMIN_NOTE = `Lưu ý: Nếu giá trị tại cửa hàng không đúng như giá hiển thị, Đối tác phải GỌI ĐIỆN CHO KHÁCH HÀNG để XÁC NHẬN lại mức giá.\n  + KH xác nhận ĐỒNG Ý, Đối tác Tiến hành mua và giữ Hoá Đơn (nếu có).\n  + KH KHÔNG ĐỒNG Ý, Đối tác gọi Tổng Đài hỗ trợ Huỷ Đơn hàng.`;

export const SERVICE_ASSISTANT_CATEGORIES = ['all', 'pharmacies', 'groceries', 'foods'];

export const OPEN_HOURS_MAP = new Map([
  ['mon', 0],
  ['tue', 1],
  ['wed', 2],
  ['thu', 3],
  ['fri', 4],
  ['sat', 5],
  ['sun', 6],
]);

export const PRICING_TIERS_LOCAL_STORAGE = 'pricing_tiers_active';
export const DEAL_ITEM_LOCAL_STORAGE = 'deal_group';
export const PAGE_SIZE = 5;
