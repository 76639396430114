import { render, staticRenderFns } from "./RequireAuth.vue?vue&type=template&id=7007186d&"
import script from "./RequireAuth.vue?vue&type=script&lang=js&"
export * from "./RequireAuth.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseTypographyTitle: require('/usr/src/app/components/base/Typography/Title.vue').default,BaseTypographyText: require('/usr/src/app/components/base/Typography/Text.vue').default,BaseTypographyParagraph: require('/usr/src/app/components/base/Typography/Paragraph.vue').default,BaseButton: require('/usr/src/app/components/base/Button/Button.vue').default,BaseResponsiveModal: require('/usr/src/app/components/base/ResponsiveModal/ResponsiveModal.vue').default})
