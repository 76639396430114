//
//
//
//
//
//

import { Space as AntSpace } from 'ant-design-vue';

export default {
  components: {
    AntSpace,
  },
};
