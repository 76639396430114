//
//
//
//
//
//
//

export default {
  props: {
    external: Boolean,
  },
};
