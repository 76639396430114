import { asyncSliceCreator } from '@/utils';
import * as groupAPI from '@/api/group';

const [groups] = asyncSliceCreator('groups', groupAPI.getListGroups);
const [groupsDetail] = asyncSliceCreator('groupsDetail', groupAPI.groupDetail);
// const [dealActive] = asyncSliceCreator('dealItemStoresById', storeAPI.storeDetail);
// export const types = {
//   SET_GROUPS_ID_ACTIVE: 'groups/set_groups_id_active',
// };

export default {
  state: () => ({
    ...groups.state(),
    ...groupsDetail.state(),
    groupsById: {
      _id: 'XXX',
      owner_id: '84944309347',
      participants: ['84944309348', '84944309347'],
      deal_id: '123',
      members_count: 2,
      max_members: 4,
      code: '01',
    },
    groupIdActive: null,
  }),
  mutations: {
    ...groups.mutations,
    ...groupsDetail.mutations,
    // [types.SET_DEAL_ITEM_ACTIVE](state, id) {
    //   state.groupIdActive = id;
    // },
  },
  actions: {
    ...groups.actions,
    ...groupsDetail.actions,

    // setGroupIdActive({ commit }, id) {
    //   commit(types.SET_GROUPS_ID_ACTIVE, id);
    // },
  },
  getters: {
    ...groups.getters,
    ...groupsDetail.getters,
    groupsById: state => state.groupsById,
    // groupIdActive: state => state.groupIdActive,
  },
};
