// import moment from 'moment'
import dayjs from 'dayjs';
import sanitize from 'sanitize-filename';
import * as constances from '@/mixins/constances';
import storageFactory from './storageFactory';

const localStorageWrapper = storageFactory();

export const calculateItemPrice = function (item = {}) {
  const quantity = (!isNaN(item.quantity) && parseInt(item.quantity)) || 0;
  let totalPrice = 0;
  totalPrice = item.price * quantity;

  if (item.customizations && item.customizations.length > 0) {
    for (var i = 0; i < item.customizations.length; i++) {
      const customization = item.customizations[i];

      for (var j = 0; j < customization.options.length; j++) {
        const option = customization.options[j];

        if (option.selected) {
          totalPrice += option.price * quantity;
        }
      }
    }
  }

  return totalPrice;
};

export const getCartItemId = function (item) {
  return item._id.toString().concat('_', new Date().getTime());
};

export const friendlyUrl = function (str, max) {
  if (typeof str !== 'string') {
    return null;
  }

  str = str.replace(/(á|à|ả|ã|ạ|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ)/g, 'a');
  str = str.replace(/(A|À|Ả|Ã|Ạ|Ă|Ắ|Ằ|Ẳ|Ẵ|Ặ|Â|Ấ|Ầ|Ẩ|Ẫ|Ậ)/g, 'A');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/Đ/g, 'D');
  str = str.replace(/(é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ)/g, 'e');
  str = str.replace(/(É|È|Ẻ|Ẽ|Ẹ|Ê|Ế|Ề|Ể|Ễ|Ệ)/g, 'E');
  str = str.replace(/(í|ì|ỉ|ĩ|ị)/g, 'i');
  str = str.replace(/(Í|Ì|Ỉ|Ĩ|Ị)/g, 'I');
  str = str.replace(/(ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ)/g, 'o');
  str = str.replace(/(Ó|Ò|Ỏ|Õ|Ọ|Ô|Ố|Ồ|Ổ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ở|Ỡ|Ợ)/g, 'O');
  str = str.replace(/(ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự)/g, 'u');
  str = str.replace(/(Ú|Ù|Ủ|Ũ|Ụ|Ư|Ứ|Ừ|Ử|Ữ|Ự)/g, 'U');
  str = str.replace(/(ý|ỳ|ỷ|ỹ|ỵ)/g, 'y');
  str = str.replace(/(Ý|Ỳ|Ỷ|Ỹ|Ỵ)/g, 'Y');

  str = str.replace(/[^a-zA-Z0-9_-]/g, '-');

  while (str.length > 0 && /--/g.test(str)) {
    str = str.replace(/--/g, '-');
  }

  if (str) {
    str = sanitize(str);
    str = str.toLowerCase();

    const limit = max || 32;
    return str.substring(0, limit);
  }

  return '';
};

export const preHTTPS = function (url) {
  let result = url ? ''.concat(url) : url;
  if (result && result.indexOf('http://') === 0) {
    result = result.replace('http://', 'https://');
  }
  return result;
};

export const getDuration = function (fromLocation, toLocation) {
  const fromLat = fromLocation && (fromLocation.latitude || fromLocation[1]);
  const fromLng = fromLocation && (fromLocation.longitude || fromLocation[0]);
  const toLat = toLocation && (toLocation.latitude || toLocation[1]);
  const toLng = toLocation && (toLocation.longitude || toLocation[0]);

  const distance = getDistance(fromLat, fromLng, toLat, toLng, 'K');
  const min = Math.ceil(distance) * 5 + 15;
  const max = min + 10;

  return { min: min, max: max, distance: distance };

  function getDistance(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
      dist = dist * 1.609344;
    }
    if (unit === 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
};

export const setFeeDetail = function (data) {
  if (data) {
    data.item_fee = data.item_fee || 0;
    data.delivery_fee = data.partner_fee || data.total_fee || 0;
    data.delivery_distance_fee = (data.partner_fee && data.partner_distance_fee) || data.distance_fee;
    data.total_discount =
      (data.item_discount || 0) + (data.partner_fee ? data.partner_discount || 0 : data.discount || 0);
    data.pay_from_balance = (data.user_main_account || 0) + (data.user_bonus_account || 0);
    data.item_vat_fee = data.item_vat_fee || 0;
    data.final_total_pay =
      data.item_fee +
      (data.partner_fee
        ? data.partner_fee - (data.item_discount || 0) - (data.partner_discount || 0)
        : data.total_fee - (data.item_discount || 0) - (data.discount || 0)) -
      data.pay_from_balance +
      data.item_vat_fee;

    if (data.currency) {
      data.currency_format = constances.CURRENCY_SYMBOL[data.currency] || data.currency;
    }
  }

  return data;
};

export const getCityInAddressComponents = function (addressComponents) {
  var result = '';
  var administrativeAreaLevel1, locality;
  if (addressComponents) {
    for (var i = 0; i < addressComponents.length; i++) {
      var value = addressComponents[i];

      if (value.types && value.types[0] === 'administrative_area_level_1') {
        administrativeAreaLevel1 = value.short_name;
      }

      if (value.types && value.types[0] === 'locality') {
        locality = value.short_name;
      }
    }

    result = administrativeAreaLevel1 || locality;
  }

  return result;
};

export const getCountryInAddressComponents = function (addressComponents) {
  var result = '';
  var country, political;
  if (addressComponents) {
    for (var i = 0; i < addressComponents.length; i++) {
      var value = addressComponents[i];

      if (value.types && value.types[0] === 'country') {
        country = value.short_name;
      }

      if (value.types && value.types[0] === 'political') {
        political = value.short_name;
      }
    }

    result = country || political;
  }

  return result;
};

const isDayOff = function (serviceHours, time) {
  if (serviceHours.day_off && serviceHours.day_off.length > 0) {
    const dateFormat = time.getDate() <= 9 ? '0'.concat(time.getDate()) : time.getDate();
    const monthFormat = time.getMonth() + 1 <= 9 ? '0'.concat(time.getMonth() + 1) : time.getMonth() + 1;
    const clientTimeFormat = ''.concat(dateFormat, '/', monthFormat);
    const isClosed =
      serviceHours.day_off.findIndex(d => {
        return d === clientTimeFormat;
      }) > -1;

    return isClosed;
  }

  return false;
};

const getOpenedTimeRange = function (serviceHours, time) {
  const isClosed = isDayOff(serviceHours, time);
  if (isClosed) {
    return false;
  }

  const dayOfWeek = getDateOfWeek(time);
  const serviceHoursOfADay = serviceHours[dayOfWeek.toString()];
  if (!serviceHoursOfADay || serviceHoursOfADay.length === 0) {
    return false;
  }

  const timeInMinus = time.getHours() * 60 + time.getMinutes();
  const result = serviceHoursOfADay.find(t => {
    return t.start_time <= timeInMinus && timeInMinus < t.end_time;
  });

  return result ? { open_ranges: { [dayOfWeek.toString()]: result } } : false;
};

const getDateOfWeek = function (time) {
  return time.getDay() === 0 ? 6 : time.getDay() - 1;
};

export const setMomentOpeningHoursFormat = function () {
  // outdate: now we use dayjs

  moment.prototype.openingHours = function () {
    const now = moment().startOf('day');
    const calendarFormat = moment.calendarFormat(this, now);
    const formatTemplate = {
      vi: {
        sameDay: '[Mở cửa vào] hh:mm A',
        nextDay: '[Mở cửa vào] hh:mm A, [ngày mai]',
        nextWeek: '[Mở cửa vào] hh:mm A, dddd',
        sameElse: '[Mở cửa vào] hh:mm A, [ngày] D [thg] M',
      },
      en: {
        sameDay: '[Opens at] hh:mm A',
        nextDay: '[Opens Tomorrow at] hh:mm A',
        nextWeek: '[Opens on] ddd [at] hh:mm: A',
        sameElse: '[Opens on] MMM d',
      },
    };
    const locale = this.locale();
    const format = formatTemplate[locale];
    if (format) {
      return this.format(format[calendarFormat]);
    } else {
      return this.calendar();
    }
  };
};

export const getOpeningStatus = function (store) {
  const serviceHours = store.service_hours;
  if (!serviceHours || serviceHours.length === 0) {
    return { open: false };
  }

  const today = new Date();
  const isOpened = getOpenedTimeRange(serviceHours, today);
  if (isOpened) {
    return Object.assign({ open: true }, isOpened);
  }

  var nextOpenTime;
  for (var i = 0; i < 30 && !nextOpenTime; i++) {
    let time = new Date();
    time.setDate(new Date().getDate() + i);
    const isOff = isDayOff(serviceHours, time);
    const dayOfWeek = getDateOfWeek(time);
    const serviceHoursOfADay = serviceHours[dayOfWeek.toString()];

    let inServiceHours;
    if (i === 0) {
      if (serviceHoursOfADay && serviceHoursOfADay.length > 0) {
        const timeInMinus = time.getHours() * 60 + time.getMinutes();
        if (serviceHoursOfADay.length === 1) {
          if (timeInMinus < serviceHoursOfADay[0].start_time) {
            inServiceHours = serviceHoursOfADay[0];
          }
        } else {
          for (var j = 0, len = serviceHoursOfADay.length; j < len; j++) {
            const t = serviceHoursOfADay[j];
            if (t.start_time > timeInMinus) {
              inServiceHours = t;
              break;
            }
          }
        }
      }
    } else {
      inServiceHours = serviceHoursOfADay && serviceHoursOfADay[0];
    }

    if (!isOff && inServiceHours && inServiceHours.start_time >= 0) {
      const startTime = inServiceHours.start_time;
      const hour = Math.floor(startTime / 60);
      const minus = startTime % 60;
      nextOpenTime = time;
      nextOpenTime.setHours(hour);
      nextOpenTime.setMinutes(minus);
    }
  }

  let nextOpenTimeLabel = '';
  if (nextOpenTime) {
    setMomentOpeningHoursFormat();
    const time = dayjs && dayjs.unix(new Date(nextOpenTime).getTime() / 1000);
    nextOpenTimeLabel = time && time.openingHours();
  }

  return nextOpenTime ? Object.assign({ open: false, next_open_time: nextOpenTimeLabel }) : { open: false };
};

export const getLocationKey = function (location) {
  if (!location) {
    return;
  }
  let lat = location.latitude || (location.coords && location.coords[1]);
  let lng = location.longitude || (location.coords && location.coords[0]);

  if (!lat || !lng) {
    try {
      lat = location.lat();
      lng = location.lng();
    } catch (e) {
      lat = location.lat;
      lng = location.lng;
    }
  }

  return `${lat}_${lng}`;
};

export const objectToQueryString = function (obj) {
  let result = '';
  for (let key in obj) {
    const conj = result === '' ? '?' : '&';
    result += conj + key + '=' + obj[key];
  }
  return result;
};

export const getCityIdByCityName = function (name) {
  const friendlyUrl = this.friendlyUrl(name);
  for (let key in constances.CITY_LOCATION_BY_DEFAULT) {
    if (constances.CITY_LOCATION_BY_DEFAULT[key].friendly_url === friendlyUrl) {
      return key;
    }
  }

  return '';
};

export const getItemStorage = function (key) {
  return localStorageWrapper.getItem(key);
};

export const setItemStorage = function (key, data) {
  localStorageWrapper.setItem(key, data);
};

export const removeItemStorage = function (key) {
  localStorageWrapper.removeItem(key);
};
export const updateItemStorage = function (key, newProps) {
  const oldVal = getItemStorage(key);
  if (oldVal) {
    const newVal = Object.assign({}, JSON.parse(oldVal), newProps);
    setItemStorage(key, JSON.stringify(newVal));
  }
};
export const getStoreLocation = (location, cityIdParam) => {
  const city = constances.CITY_LOCATION_BY_DEFAULT[cityIdParam];
  if (location && location.city && location.longitude && location.latitude) {
    const userCity = friendlyUrl(location.city);
    if (!city || userCity.indexOf(city.friendly_url) > -1) {
      return {
        latitude: location.latitude,
        longitude: location.longitude,
      };
    }
  }
  return {
    latitude: city.latitude,
    longitude: city.longitude,
  };
};
export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const getRadiusConfig = function (firebaseRemoteConfig) {
  const radius =
    firebaseRemoteConfig &&
    firebaseRemoteConfig.lala_nearby_stores_valid_distance &&
    firebaseRemoteConfig.lala_nearby_stores_valid_distance.defaultValue &&
    firebaseRemoteConfig.lala_nearby_stores_valid_distance.defaultValue.value;
  return radius;
};

export const getSomeInfoItem = cartItems => {
  let quantity = 0;
  const item_id = [];
  const type_id = [];
  cartItems.forEach(item => {
    quantity += item.quantity;
    type_id.push(item.item_type_id);
    item_id.push(item._id);
  });

  return {
    quantity,
    item_id,
    type_id,
  };
};
