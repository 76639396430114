if (process.env.NUXT_ENV_ENABLE_ERUDA === 'true') {
  (function () {
    const src = '//cdn.jsdelivr.net/npm/eruda';

    const tag = document.createElement('script');
    tag.setAttribute('src', src);
    tag.setAttribute('type', 'text/javascript');

    tag.onload = () => {
      eruda.init();
    };

    document.head.appendChild(tag);
  })();
}
