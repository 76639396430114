import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e02911d4 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _d08bb2ac = () => interopDefault(import('../pages/deals/index.vue' /* webpackChunkName: "pages/deals/index" */))
const _5258332a = () => interopDefault(import('../pages/delivery-address.vue' /* webpackChunkName: "pages/delivery-address" */))
const _3da47a04 = () => interopDefault(import('../pages/re-order.vue' /* webpackChunkName: "pages/re-order" */))
const _6b9e8710 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _adc44d12 = () => interopDefault(import('../pages/groups/create.vue' /* webpackChunkName: "pages/groups/create" */))
const _beb96a52 = () => interopDefault(import('../pages/brands/_slug.vue' /* webpackChunkName: "pages/brands/_slug" */))
const _d3f8f53c = () => interopDefault(import('../pages/deals/_slug.vue' /* webpackChunkName: "pages/deals/_slug" */))
const _6ae8a13d = () => interopDefault(import('../pages/stores/_slug.vue' /* webpackChunkName: "pages/stores/_slug" */))
const _70ca8994 = () => interopDefault(import('../pages/tags/_id.vue' /* webpackChunkName: "pages/tags/_id" */))
const _1a71eda2 = () => interopDefault(import('../pages/update-item/_id.vue' /* webpackChunkName: "pages/update-item/_id" */))
const _3789d637 = () => interopDefault(import('../pages/groups/_slug/estimated/_id.vue' /* webpackChunkName: "pages/groups/_slug/estimated/_id" */))
const _56235b0a = () => interopDefault(import('../pages/groups/_slug/_id.vue' /* webpackChunkName: "pages/groups/_slug/_id" */))
const _31dd164e = () => interopDefault(import('../pages/order-groups/_group_id/_id.vue' /* webpackChunkName: "pages/order-groups/_group_id/_id" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _e02911d4,
    name: "checkout"
  }, {
    path: "/deals",
    component: _d08bb2ac,
    name: "deals"
  }, {
    path: "/delivery-address",
    component: _5258332a,
    name: "delivery-address"
  }, {
    path: "/re-order",
    component: _3da47a04,
    name: "re-order"
  }, {
    path: "/search",
    component: _6b9e8710,
    name: "search"
  }, {
    path: "/groups/create",
    component: _adc44d12,
    name: "groups-create"
  }, {
    path: "/brands/:slug?",
    component: _beb96a52,
    name: "brands-slug"
  }, {
    path: "/deals/:slug",
    component: _d3f8f53c,
    name: "deals-slug"
  }, {
    path: "/stores/:slug?",
    component: _6ae8a13d,
    name: "stores-slug"
  }, {
    path: "/tags/:id?",
    component: _70ca8994,
    name: "tags-id"
  }, {
    path: "/update-item/:id?",
    component: _1a71eda2,
    name: "update-item-id"
  }, {
    path: "/groups/:slug?/estimated/:id?",
    component: _3789d637,
    name: "groups-slug-estimated-id"
  }, {
    path: "/groups/:slug?/:id?",
    component: _56235b0a,
    name: "groups-slug-id"
  }, {
    path: "/order-groups/:group_id?/:id?",
    component: _31dd164e,
    name: "order-groups-group_id-id"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/w_ahamove/checkout",
    component: _e02911d4,
    name: "checkout___w_ahamove"
  }, {
    path: "/ahamove/checkout",
    component: _e02911d4,
    name: "checkout___ahamove"
  }, {
    path: "/momo_deeplink/checkout",
    component: _e02911d4,
    name: "checkout___momo_deeplink"
  }, {
    path: "/zalo/checkout",
    component: _e02911d4,
    name: "checkout___zalo"
  }, {
    path: "/zalopay/checkout",
    component: _e02911d4,
    name: "checkout___zalopay"
  }, {
    path: "/viettel-pay_deeplink/checkout",
    component: _e02911d4,
    name: "checkout___viettel-pay_deeplink"
  }, {
    path: "/smartpay_deeplink/checkout",
    component: _e02911d4,
    name: "checkout___smartpay_deeplink"
  }, {
    path: "/w_ahamove/deals",
    component: _d08bb2ac,
    name: "deals___w_ahamove"
  }, {
    path: "/ahamove/deals",
    component: _d08bb2ac,
    name: "deals___ahamove"
  }, {
    path: "/momo_deeplink/deals",
    component: _d08bb2ac,
    name: "deals___momo_deeplink"
  }, {
    path: "/zalo/deals",
    component: _d08bb2ac,
    name: "deals___zalo"
  }, {
    path: "/zalopay/deals",
    component: _d08bb2ac,
    name: "deals___zalopay"
  }, {
    path: "/viettel-pay_deeplink/deals",
    component: _d08bb2ac,
    name: "deals___viettel-pay_deeplink"
  }, {
    path: "/smartpay_deeplink/deals",
    component: _d08bb2ac,
    name: "deals___smartpay_deeplink"
  }, {
    path: "/w_ahamove/delivery-address",
    component: _5258332a,
    name: "delivery-address___w_ahamove"
  }, {
    path: "/ahamove/delivery-address",
    component: _5258332a,
    name: "delivery-address___ahamove"
  }, {
    path: "/momo_deeplink/delivery-address",
    component: _5258332a,
    name: "delivery-address___momo_deeplink"
  }, {
    path: "/zalo/delivery-address",
    component: _5258332a,
    name: "delivery-address___zalo"
  }, {
    path: "/zalopay/delivery-address",
    component: _5258332a,
    name: "delivery-address___zalopay"
  }, {
    path: "/viettel-pay_deeplink/delivery-address",
    component: _5258332a,
    name: "delivery-address___viettel-pay_deeplink"
  }, {
    path: "/smartpay_deeplink/delivery-address",
    component: _5258332a,
    name: "delivery-address___smartpay_deeplink"
  }, {
    path: "/w_ahamove/re-order",
    component: _3da47a04,
    name: "re-order___w_ahamove"
  }, {
    path: "/ahamove/re-order",
    component: _3da47a04,
    name: "re-order___ahamove"
  }, {
    path: "/momo_deeplink/re-order",
    component: _3da47a04,
    name: "re-order___momo_deeplink"
  }, {
    path: "/zalo/re-order",
    component: _3da47a04,
    name: "re-order___zalo"
  }, {
    path: "/zalopay/re-order",
    component: _3da47a04,
    name: "re-order___zalopay"
  }, {
    path: "/viettel-pay_deeplink/re-order",
    component: _3da47a04,
    name: "re-order___viettel-pay_deeplink"
  }, {
    path: "/smartpay_deeplink/re-order",
    component: _3da47a04,
    name: "re-order___smartpay_deeplink"
  }, {
    path: "/w_ahamove/search",
    component: _6b9e8710,
    name: "search___w_ahamove"
  }, {
    path: "/ahamove/search",
    component: _6b9e8710,
    name: "search___ahamove"
  }, {
    path: "/momo_deeplink/search",
    component: _6b9e8710,
    name: "search___momo_deeplink"
  }, {
    path: "/zalo/search",
    component: _6b9e8710,
    name: "search___zalo"
  }, {
    path: "/zalopay/search",
    component: _6b9e8710,
    name: "search___zalopay"
  }, {
    path: "/viettel-pay_deeplink/search",
    component: _6b9e8710,
    name: "search___viettel-pay_deeplink"
  }, {
    path: "/smartpay_deeplink/search",
    component: _6b9e8710,
    name: "search___smartpay_deeplink"
  }, {
    path: "/w_ahamove/groups/create",
    component: _adc44d12,
    name: "groups-create___w_ahamove"
  }, {
    path: "/ahamove/groups/create",
    component: _adc44d12,
    name: "groups-create___ahamove"
  }, {
    path: "/momo_deeplink/groups/create",
    component: _adc44d12,
    name: "groups-create___momo_deeplink"
  }, {
    path: "/zalo/groups/create",
    component: _adc44d12,
    name: "groups-create___zalo"
  }, {
    path: "/zalopay/groups/create",
    component: _adc44d12,
    name: "groups-create___zalopay"
  }, {
    path: "/viettel-pay_deeplink/groups/create",
    component: _adc44d12,
    name: "groups-create___viettel-pay_deeplink"
  }, {
    path: "/smartpay_deeplink/groups/create",
    component: _adc44d12,
    name: "groups-create___smartpay_deeplink"
  }, {
    path: "/w_ahamove/brands/:slug?",
    component: _beb96a52,
    name: "brands-slug___w_ahamove"
  }, {
    path: "/ahamove/brands/:slug?",
    component: _beb96a52,
    name: "brands-slug___ahamove"
  }, {
    path: "/momo_deeplink/brands/:slug?",
    component: _beb96a52,
    name: "brands-slug___momo_deeplink"
  }, {
    path: "/zalo/brands/:slug?",
    component: _beb96a52,
    name: "brands-slug___zalo"
  }, {
    path: "/zalopay/brands/:slug?",
    component: _beb96a52,
    name: "brands-slug___zalopay"
  }, {
    path: "/viettel-pay_deeplink/brands/:slug?",
    component: _beb96a52,
    name: "brands-slug___viettel-pay_deeplink"
  }, {
    path: "/smartpay_deeplink/brands/:slug?",
    component: _beb96a52,
    name: "brands-slug___smartpay_deeplink"
  }, {
    path: "/w_ahamove/deals/:slug",
    component: _d3f8f53c,
    name: "deals-slug___w_ahamove"
  }, {
    path: "/ahamove/deals/:slug",
    component: _d3f8f53c,
    name: "deals-slug___ahamove"
  }, {
    path: "/momo_deeplink/deals/:slug",
    component: _d3f8f53c,
    name: "deals-slug___momo_deeplink"
  }, {
    path: "/zalo/deals/:slug",
    component: _d3f8f53c,
    name: "deals-slug___zalo"
  }, {
    path: "/zalopay/deals/:slug",
    component: _d3f8f53c,
    name: "deals-slug___zalopay"
  }, {
    path: "/viettel-pay_deeplink/deals/:slug",
    component: _d3f8f53c,
    name: "deals-slug___viettel-pay_deeplink"
  }, {
    path: "/smartpay_deeplink/deals/:slug",
    component: _d3f8f53c,
    name: "deals-slug___smartpay_deeplink"
  }, {
    path: "/w_ahamove/stores/:slug?",
    component: _6ae8a13d,
    name: "stores-slug___w_ahamove"
  }, {
    path: "/ahamove/stores/:slug?",
    component: _6ae8a13d,
    name: "stores-slug___ahamove"
  }, {
    path: "/momo_deeplink/stores/:slug?",
    component: _6ae8a13d,
    name: "stores-slug___momo_deeplink"
  }, {
    path: "/zalo/stores/:slug?",
    component: _6ae8a13d,
    name: "stores-slug___zalo"
  }, {
    path: "/zalopay/stores/:slug?",
    component: _6ae8a13d,
    name: "stores-slug___zalopay"
  }, {
    path: "/viettel-pay_deeplink/stores/:slug?",
    component: _6ae8a13d,
    name: "stores-slug___viettel-pay_deeplink"
  }, {
    path: "/smartpay_deeplink/stores/:slug?",
    component: _6ae8a13d,
    name: "stores-slug___smartpay_deeplink"
  }, {
    path: "/w_ahamove/tags/:id?",
    component: _70ca8994,
    name: "tags-id___w_ahamove"
  }, {
    path: "/ahamove/tags/:id?",
    component: _70ca8994,
    name: "tags-id___ahamove"
  }, {
    path: "/momo_deeplink/tags/:id?",
    component: _70ca8994,
    name: "tags-id___momo_deeplink"
  }, {
    path: "/zalo/tags/:id?",
    component: _70ca8994,
    name: "tags-id___zalo"
  }, {
    path: "/zalopay/tags/:id?",
    component: _70ca8994,
    name: "tags-id___zalopay"
  }, {
    path: "/viettel-pay_deeplink/tags/:id?",
    component: _70ca8994,
    name: "tags-id___viettel-pay_deeplink"
  }, {
    path: "/smartpay_deeplink/tags/:id?",
    component: _70ca8994,
    name: "tags-id___smartpay_deeplink"
  }, {
    path: "/w_ahamove/update-item/:id?",
    component: _1a71eda2,
    name: "update-item-id___w_ahamove"
  }, {
    path: "/ahamove/update-item/:id?",
    component: _1a71eda2,
    name: "update-item-id___ahamove"
  }, {
    path: "/momo_deeplink/update-item/:id?",
    component: _1a71eda2,
    name: "update-item-id___momo_deeplink"
  }, {
    path: "/zalo/update-item/:id?",
    component: _1a71eda2,
    name: "update-item-id___zalo"
  }, {
    path: "/zalopay/update-item/:id?",
    component: _1a71eda2,
    name: "update-item-id___zalopay"
  }, {
    path: "/viettel-pay_deeplink/update-item/:id?",
    component: _1a71eda2,
    name: "update-item-id___viettel-pay_deeplink"
  }, {
    path: "/smartpay_deeplink/update-item/:id?",
    component: _1a71eda2,
    name: "update-item-id___smartpay_deeplink"
  }, {
    path: "/w_ahamove/groups/:slug?/estimated/:id?",
    component: _3789d637,
    name: "groups-slug-estimated-id___w_ahamove"
  }, {
    path: "/ahamove/groups/:slug?/estimated/:id?",
    component: _3789d637,
    name: "groups-slug-estimated-id___ahamove"
  }, {
    path: "/momo_deeplink/groups/:slug?/estimated/:id?",
    component: _3789d637,
    name: "groups-slug-estimated-id___momo_deeplink"
  }, {
    path: "/zalo/groups/:slug?/estimated/:id?",
    component: _3789d637,
    name: "groups-slug-estimated-id___zalo"
  }, {
    path: "/zalopay/groups/:slug?/estimated/:id?",
    component: _3789d637,
    name: "groups-slug-estimated-id___zalopay"
  }, {
    path: "/viettel-pay_deeplink/groups/:slug?/estimated/:id?",
    component: _3789d637,
    name: "groups-slug-estimated-id___viettel-pay_deeplink"
  }, {
    path: "/smartpay_deeplink/groups/:slug?/estimated/:id?",
    component: _3789d637,
    name: "groups-slug-estimated-id___smartpay_deeplink"
  }, {
    path: "/w_ahamove/groups/:slug?/:id?",
    component: _56235b0a,
    name: "groups-slug-id___w_ahamove"
  }, {
    path: "/ahamove/groups/:slug?/:id?",
    component: _56235b0a,
    name: "groups-slug-id___ahamove"
  }, {
    path: "/momo_deeplink/groups/:slug?/:id?",
    component: _56235b0a,
    name: "groups-slug-id___momo_deeplink"
  }, {
    path: "/zalo/groups/:slug?/:id?",
    component: _56235b0a,
    name: "groups-slug-id___zalo"
  }, {
    path: "/zalopay/groups/:slug?/:id?",
    component: _56235b0a,
    name: "groups-slug-id___zalopay"
  }, {
    path: "/viettel-pay_deeplink/groups/:slug?/:id?",
    component: _56235b0a,
    name: "groups-slug-id___viettel-pay_deeplink"
  }, {
    path: "/smartpay_deeplink/groups/:slug?/:id?",
    component: _56235b0a,
    name: "groups-slug-id___smartpay_deeplink"
  }, {
    path: "/w_ahamove/order-groups/:group_id?/:id?",
    component: _31dd164e,
    name: "order-groups-group_id-id___w_ahamove"
  }, {
    path: "/ahamove/order-groups/:group_id?/:id?",
    component: _31dd164e,
    name: "order-groups-group_id-id___ahamove"
  }, {
    path: "/momo_deeplink/order-groups/:group_id?/:id?",
    component: _31dd164e,
    name: "order-groups-group_id-id___momo_deeplink"
  }, {
    path: "/zalo/order-groups/:group_id?/:id?",
    component: _31dd164e,
    name: "order-groups-group_id-id___zalo"
  }, {
    path: "/zalopay/order-groups/:group_id?/:id?",
    component: _31dd164e,
    name: "order-groups-group_id-id___zalopay"
  }, {
    path: "/viettel-pay_deeplink/order-groups/:group_id?/:id?",
    component: _31dd164e,
    name: "order-groups-group_id-id___viettel-pay_deeplink"
  }, {
    path: "/smartpay_deeplink/order-groups/:group_id?/:id?",
    component: _31dd164e,
    name: "order-groups-group_id-id___smartpay_deeplink"
  }, {
    path: "/w_ahamove/",
    component: _e542f99c,
    name: "index___w_ahamove"
  }, {
    path: "/ahamove/",
    component: _e542f99c,
    name: "index___ahamove"
  }, {
    path: "/momo_deeplink/",
    component: _e542f99c,
    name: "index___momo_deeplink"
  }, {
    path: "/zalo/",
    component: _e542f99c,
    name: "index___zalo"
  }, {
    path: "/zalopay/",
    component: _e542f99c,
    name: "index___zalopay"
  }, {
    path: "/viettel-pay_deeplink/",
    component: _e542f99c,
    name: "index___viettel-pay_deeplink"
  }, {
    path: "/smartpay_deeplink/",
    component: _e542f99c,
    name: "index___smartpay_deeplink"
  }],

  fallback: true
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
