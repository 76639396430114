import * as storeAPI from '@/api/store';
import { asyncSliceCreator } from '@/utils';
import { PLATFORMS } from '@/mixins/constances';

const [slice, { name }] = asyncSliceCreator('screenConfigs', storeAPI.getScreenConfigs);

export default {
  state: () => ({
    ...slice.state(),
  }),
  mutations: {
    ...slice.mutations,
  },
  actions: {
    ...slice.actions,
  },
  getters: {
    ...slice.getters,
    homePageBanners: (state, getters, rootState) => {
      // Default is WEB
      const platform = rootState.webInAppConfig?.platform ?? PLATFORMS.WEB;

      return (
        state[name].data?.configs?.banner_goshop
          ?.filter(banner => banner.enable && (!banner.platform || banner.platform.includes(platform)))
          .sort((a, b) => a.order - b.order)
          .map(banner => {
            if (banner.link?.includes(process.env.NUXT_ENV_BASE_URL)) {
              banner.internalLink = banner.link.replace(process.env.NUXT_ENV_BASE_URL, '');
            }
            return banner;
          }) ?? []
      );
    },
  },
};
