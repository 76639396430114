import { render, staticRenderFns } from "./defaultV2.vue?vue&type=template&id=b8058ba4&"
import script from "./defaultV2.vue?vue&type=script&lang=js&"
export * from "./defaultV2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SharedRequireAuth: require('/usr/src/app/components/shared/RequireAuth/RequireAuth.vue').default,BaseLayoutContent: require('/usr/src/app/components/base/Layout/Content.vue').default,BaseLayout: require('/usr/src/app/components/base/Layout/Layout.vue').default,SharedPageFooter: require('/usr/src/app/components/shared/PageFooter/PageFooter.vue').default})
