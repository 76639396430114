import { getToken, getUserProfileFromToken, storeToken } from '@/plugins/utils';
import { getCookie, setCookie } from '@/plugins/utils';
import { COOKIE_EXPIRES_MAX_AGE__3M } from '@/mixins/constances';

export default async context => {
  const { app, store, route } = context;
  const { token } = route.query;

  if (!!token) {
    store.dispatch('setToken', token);
    storeToken(token);
    setCookie('token', token, {
      exdays: COOKIE_EXPIRES_MAX_AGE__3M,
      domain: process.env.NUXT_ENV_APP_DOMAIN,
    });

    const userProfile = getUserProfileFromToken(token);
    if (userProfile) store.dispatch('setUserProfile', userProfile);
  } else if (!store.state.token) {
    const token = getCookie('token') || getToken() || '';
    if (token) store.dispatch('setToken', token);
  }

  app.router.beforeEach((to, from, next) => {
    if (to.query.token) {
      const query = { ...to.query };
      delete query.token;
      return next({ ...to, query });
    }

    if (!store.state.userProfile || !store.state.userProfile.mobile) {
      const userProfile = getUserProfileFromToken(store.state.token);
      if (userProfile) store.dispatch('setUserProfile', userProfile);
    }

    next();
  });
};
