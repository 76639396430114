import { get } from '~/api/API';
import { API } from '~/mixins/constances';

export const storeDetail = param => {
  const uri = API.store.detail;

  let baseURL = process.env.NUXT_ENV_API_URL;

  if (process.server && process.env.NUXT_ENV_API_WEB_INTERNAL_URL) {
    baseURL = process.env.NUXT_ENV_API_WEB_INTERNAL_URL;
  }

  return get(uri, param, {
    baseURL,
    token_required: false,
  });
};

export const listStore = (params = {}, config = {}) => {
  const uri = API.store.nearby_stores;
  return get(uri, params, {
    token_required: false,
    ...config,
  });
};

export const getStoreBrands = (params = {}) => {
  const uri = API.store.brand;
  return get(uri, params, {
    token_required: false,
  });
};

export const searchStore = params => {
  const uri = API.store.search;
  return get(uri, params, {
    token_required: false,
  });
};

export const getScreenConfigs = params => {
  const uri = API.master_data.screen_configs;
  return get(uri, params, {
    baseURL: process.env.NUXT_ENV_API_BE_URL,
    token_required: false,
  });
};

export const getStoreTags = (params = {}) => {
  const uri = API.store.tag;
  return get(uri, params, {
    token_required: false,
  });
};

export const searchStoreNew = (params = {}) => {
  const uri = API.store.search_new;
  return get(uri, params, {
    token_required: false,
  });
};
