//
//
//
//
//
//

import { Button as AntButton } from 'ant-design-vue';

export default {
  components: {
    AntButton,
  },
};
